import React from 'react';
import {Link} from "react-router-dom";
import SimpleBar from "simplebar-react";


export default function AudienceNavHeader({}) {


    const urlStartsWith = (url) => {
        return window.location.pathname.startsWith(url);
    }
    const urlMatches = (regexp) => {
        return window.location.pathname.match(regexp);
    }


    return (
        <SimpleBar autoHide={true} className="top-level">
            <div className="top-level-content">
                <Link to="/promo" className={(urlMatches('\/p(?!romo\/|\/tasks|\/competitors).*') ? ' active' : '')}>Аудиторії</Link>
                <Link to="/promo/tasks" className={(urlStartsWith('/p/tasks') ? ' active' : '')}>Бюджети</Link>
            </div>
        </SimpleBar>
    );
}