import React, {useEffect} from "react";

import './PublicationPreview.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import NL2P from "../../../ui/NL2P";
import PublicationCaptionPreview from "./PublicationCaptionPreview";
import PublicationPreviewInstagramSlider from "./PublicationPreviewInstagramSlider";
import NL2BR from "../../../ui/NL2BR";
import PublicationPreviewFacebookCollage from "./PublicationPreviewFacebookCollage";

export default function PublicationPreview({publication = {}}) {

    const [caption, setCaption] = React.useState(null);
    const [prefix, setPrefix] = React.useState(null);
    const [showFullCaption, setShowFullCaption] = React.useState(false);


    useEffect(() => {
        let newCaption = '';
        if (publication && publication.latestVersion && publication.latestVersion.text) {
            newCaption = publication.latestVersion.text;
        }
        setCaption(newCaption);

        let captionPrefix = '';
        if (publication.platform === 'instagram' && publication.plan.project.accounts.instagram.username) {
            captionPrefix = (<strong>{publication.plan.project.accounts.instagram.username} </strong>);
        }
        if (!newCaption) {
            captionPrefix = (<>{captionPrefix} <em>Без підпису</em></>);
        }
        setPrefix(captionPrefix);
    }, [publication]);


    const onShowMore = (e) => {
        e.preventDefault();
        setShowFullCaption(true);
    }


    const onShowLess = (e) => {
        e.preventDefault();
        setShowFullCaption(false);
    }


    return (<div className="publication-edit-preview">
        <div style={{width: '100%', backgroundColor: '#fff', border: '1px #dadada solid'}}>
            {['stories', 'instagram'].includes(publication.platform) ? (
            <PublicationPreviewInstagramSlider publication={publication} />
            ) : (
            <PublicationPreviewFacebookCollage publication={publication} />
            )}
        </div>

        <div className={'publication-edit-preview-caption' + (publication.platform === 'stories' ? ' d-none' : '')}>
            {showFullCaption ? (<>
                <NL2BR
                    prefix={prefix}
                    content={caption}
                />
                <a href="#" onClick={onShowLess}>менше</a>
            </>) : (<>
                <PublicationCaptionPreview
                    caption={caption}
                    prefix={prefix}
                    platform={publication.platform}
                    onExpand={onShowMore}
                />
            </>)}
        </div>
    </div>);
}