import React, {useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import FormCheckbox from "../../layout/ui/FormCheckbox";
import SimpleCardContainer from "../../ui/SimpleCardContainer";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import {IconArrowLeft} from "@tabler/icons-react";
import {useApi} from "../../../providers/ApiProvider";
import {useMeta} from "../../../providers/MetaProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsRequests from "../../../requests/Projects/ProjectsRequests";
import PublicationPlansRequests from "../../../requests/Publications/PublicationPlansRequests";
import PublicationsRequests from "../../../requests/Publications/PublicationsRequests";
import SectionNav from "../../layout/SectionNav";
import Grades from "../../../helpers/Grades";
import ProjectThemesRequests from "../../../requests/Projects/ProjectThemesRequests";
import {useAuth} from "../../../providers/AuthProvider";
import FormDateField from "../../layout/ui/FormDateField";
import FormSelect from "../../layout/ui/FormSelect";
import FormTextArea from "../../layout/ui/FormTextArea";


export default function PublicationEditForm() {
    const {planId, publicationId} = useParams();
    const apiContext = useApi();
    const metaContext = useMeta();
    const authContext = useAuth();
    const projectEssentials = authContext.project;
    const notificationsContext = useNotifications();
    const projectsRequests = new ProjectsRequests(apiContext.api);
    const projectThemesModel = new ProjectThemesRequests(apiContext.api, projectEssentials.id);
    const publicationPlansModel = new PublicationPlansRequests(apiContext.api, projectEssentials.id);
    const publicationsRequests = new PublicationsRequests(apiContext.api);

    const [loading, setLoading] = React.useState('general');
    const [errors, setErrors] = React.useState({});
    const [themes, setThemes] = React.useState([]);
    const [plans, setPlans] = React.useState([]);
    const [publication, setPublication] = React.useState({});
    const [publicationType, setPublicationType] = React.useState('');
    const [publicationPlatform, setPublicationPlatform] = React.useState('');
    const [publicationTheme, setPublicationTheme] = React.useState('');
    const [publicationDate, setPublicationDate] = React.useState('');
    const [publicationTime, setPublicationTime] = React.useState('');
    const [publicationNotes, setPublicationNotes] = React.useState('');
    const [publicationPriority, setPublicationPriority] = React.useState('');
    const [publicationPlanId, setPublicationPlanId] = React.useState(planId);
    const [publicationStatus, setPublicationStatus] = React.useState('');
    const [publicationPublishedData, setPublicationPublishedData] = React.useState({});
    const [statuses, setStatuses] = React.useState([]);
    const [showAdditionalOptions, setShowAdditionalOptions] = React.useState(false);


    useEffect(() => {
        getData();
        getThemes();
        getPlans();
    }, []);


    const getThemes = () => {
        projectThemesModel.list().then((response) => {
            if (response !== false && response.data.length > 0) {
                let themesList = [];
                response.data.forEach((theme) => {
                    themesList.push({value: theme.id, label: theme.title});
                });

                setThemes(themesList);
            } else {
                setThemes([]);
            }
        });
    }


    const getPlans = () => {
        publicationPlansModel.getPlansAround(planId).then((response) => {
            if (response !== false && response.length > 0) {
                let plansList = [];
                response.forEach((plan) => {
                    plansList.push({value: plan.id, label: Grades.getDateSinceFormatted(plan.date_since)});
                });

                setPlans(plansList);
            } else {
                setPlans([]);
            }
        });
    }


    const getData = () => {
        setLoading('general');

        publicationsRequests.view(publicationId).then((response) => {
            if (response !== false) {
                setPublication(response);

                if (response.parameters && response.parameters.type) {setPublicationType(response.parameters.type);} else {setPublicationType('');}
                if (response.platform) {setPublicationPlatform(response.platform);} else {setPublicationPlatform('');}
                if (response.theme) {setPublicationTheme(response.theme.id);} else {setPublicationTheme('');}
                if (response.status) {setPublicationStatus(response.status);} else {setPublicationStatus('');}
                if (response.statuses) {
                    setStatuses(Object.keys(response.statuses).map((status) => {
                        return {value: status, label: response.statuses[status]};
                    }));
                } else {setStatuses([]);}
                if (response.scheduled_for) {
                    const rawScheduledFor = new Date(response.scheduled_for);
                    const localScheduledFor = new Date(rawScheduledFor.getTime() - rawScheduledFor.getTimezoneOffset() * 60000);
                    const scheduledFor = localScheduledFor.toISOString().slice(0, 16);
                    const scheduledForParts = scheduledFor.split('T');

                    setPublicationDate(scheduledForParts[0]);
                    setPublicationTime(scheduledForParts[1]);
                } else {
                    setPublicationDate('');
                    setPublicationTime('');
                }
                if (response.notes) {setPublicationNotes(response.notes);} else {setPublicationNotes('');}
                if (response.parameters && response.parameters.priority) {setPublicationPriority(response.parameters.priority);} else {setPublicationPriority('');}
                if (response.published_data) {setPublicationPublishedData(response.published_data);} else {setPublicationPublishedData({});}


                const pageTitle = 'Редагування публікації в ' + response.platforms[response.platform] + ' від ' + Grades.getDateSinceFormatted(response.scheduled_for);
                metaContext.update({title: pageTitle});
            }
        }).finally(() => {
            setLoading('');
        });
    }


    const onSave = () => {
        setLoading('general');

        const localDateTime = publicationDate + 'T' + publicationTime+':00';
        const scheduledFor = new Date(localDateTime);

        const data = {
            type: publicationType,
            platform: publicationPlatform,
            theme_id: publicationTheme,
            scheduled_for: scheduledFor.toISOString(),
            notes: publicationNotes,
            priority: publicationPriority,
            plan_id: publicationPlanId,
            status: publicationStatus,
            published_data: publicationPublishedData
        }

        publicationsRequests.update(publicationId, data).then((response) => {
            if (response !== false) {
                getData();

                notificationsContext.notify('Публікацію успішно збережено.', 'success');
            } else {
                notificationsContext.notify('Помилка збереження публікації.', 'danger');
            }
        }).finally(() => {
            setLoading('');
        });
    }


    const checkIfPostWouldBeRemoved = (newStatus, oldStatus) => {
        const errorText = 'Публікація буде видалена з Facebook.';
        let shouldBeRemoved = false;
        if (publicationPlatform === 'facebook') {
            if (['scheduled', 'published'].includes(oldStatus) && ['draft', 'under_review'].includes(newStatus)) {
                if (publicationPublishedData && publicationPublishedData.id) {
                    shouldBeRemoved = true;
                }
            }
        }

        let newErrors = {...errors};
        if (shouldBeRemoved) {
            if (!newErrors['status']) {
                newErrors['status'] = [];
            }
            newErrors['status'] = newErrors['status'].filter((error) => error !== errorText);
            newErrors['status'].push(errorText);
        } else {
            newErrors['status'] = newErrors['status'].filter((error) => error !== errorText);
        }
        setErrors(newErrors);
    }


    const editAllowed = publication && ['draft', 'under_review'].includes(publication.status);


    return (<>
        <SectionNav plan={publication.plan} displayEdit={false} />

        <div className="publication-edit">
            <div className="publication-edit-header">
                <div className="back-button-container">
                    <Link to={'/' + ['p', planId, publicationId].join('/')}
                          className="btn btn-sm btn-light-primary text-primary justify-content-center align-items-center px-0 w-auto">
                        <IconArrowLeft size={20} stroke={2}/>
                    </Link>
                </div>
                <div className="header-container">
                    <SimpleCardContainer
                        className="form-container"
                        cardBodyClassName={'p-0'}
                        loading={loading === 'general'}
                    >
                        <h1 className="h3 mb-st">Редагування інформації про публікацію</h1>

                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group mb-3">
                                    <label htmlFor="pub-type" className="form-label">Тип матеріалу</label>
                                    <select className="form-select" value={publicationType} disabled={!editAllowed} onChange={(e) => setPublicationType(e.target.value)} id="pub-type">
                                        <option value="entertainment">Розважальний</option>
                                        <option value="educational">Пізнавальний</option>
                                        <option value="sales">Продажний</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mb-3">
                                    <label htmlFor="pub-platform" className="form-label">Платформа</label>
                                    <select className="form-select" value={publicationPlatform} disabled={!editAllowed} onChange={(e) => setPublicationPlatform(e.target.value)}
                                            id="pub-platform">
                                        <option value="instagram">Instagram</option>
                                        <option value="facebook">Facebook</option>
                                        <option value="stories">Історії Instagram</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="form-group mb-3">
                            <label htmlFor="pub-theme" className="form-label">Тема</label>
                            <select className="form-select" value={publicationTheme} disabled={!editAllowed} onChange={(e) => setPublicationTheme(e.target.value)} id="pub-theme">
                                {themes.map((theme, index) => (
                                    <option key={index} value={theme.value}>{theme.label}</option>
                                ))}
                            </select>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <FormDateField
                                    label={'Дата публікації'}
                                    name={'publicationDate'}
                                    errors={errors}
                                    onChange={(value) => {
                                        setPublicationDate(value);
                                    }}
                                    value={publicationDate}
                                    disabled={!editAllowed}
                                    groupClassName={'mb-3'}
                                />
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mb-3">
                                    <label htmlFor="pub-time" className="form-label">Час</label>
                                    <input type="time" className="form-control" value={publicationTime} disabled={!editAllowed} onChange={(e) => setPublicationTime(e.target.value)}
                                           id="pub-time"/>
                                </div>
                            </div>
                        </div>

                        <div className="form-group mb-3">
                            <label htmlFor="pub-notes" className="form-label">Нотатки до публікації</label>
                            <textarea rows={3} className="form-control" value={publicationNotes} disabled={!editAllowed} onChange={(e) => setPublicationNotes(e.target.value)}
                                      id="pub-notes"/>
                        </div>

                        <FormCheckbox
                            label={'Пріоритетна публікація'}
                            name={'priority'}
                            errors={errors}
                            onChange={(value) => {
                                setPublicationPriority(value);
                            }}
                            disabled={!editAllowed}
                            value={publicationPriority}
                            groupClassName={'mb-3'}
                        />

                        <div className="form-check mb-3 form-switch">
                            <input className="form-check-input" type="checkbox" id="publication-plan-id-show" checked={!!showAdditionalOptions}
                                   onChange={() => setShowAdditionalOptions(!showAdditionalOptions)}/>
                            <label className="form-check-label" htmlFor="publication-plan-id-show">Показати додаткові налаштування</label>
                        </div>

                        {showAdditionalOptions ? (<>
                            <div className="row">
                                <div className="col-lg-7">
                                    <FormSelect
                                        label={'План публікації'}
                                        name={'planId'}
                                        errors={errors}
                                        onChange={(value) => {
                                            setPublicationPlanId(value);
                                        }}
                                        options={plans}
                                        value={publicationPlanId}
                                        groupClassName={'mb-3'}
                                    />
                                </div>
                                <div className="col-lg-5">
                                    <FormSelect
                                        label={'Статус'}
                                        name={'status'}
                                        errors={errors}
                                        onChange={(value) => {
                                            checkIfPostWouldBeRemoved(value, publication.status);
                                            setPublicationStatus(value);
                                        }}
                                        options={statuses}
                                        value={publicationStatus}
                                        groupClassName={'mb-3'}
                                    />
                                </div>
                            </div>

                            <FormTextArea
                                label={'Дані про публікацію'}
                                name={'publishedData'}
                                errors={errors}
                                onChange={(value) => {
                                    setPublicationPublishedData(value);
                                }}
                                value={typeof publicationPublishedData === 'object' ? JSON.stringify(publicationPublishedData, null, 2) : publicationPublishedData}
                                groupClassName={'mb-3'}
                                isCodeMirror={true}
                            />
                        </>) : ''}

                        <div className="d-flex gap-3">
                            <ButtonWithLoading
                                label={'Зберегти'}
                                className={'btn-primary'}
                                onClick={onSave}
                                iconClassName={"d-none"}
                                loading={false}
                            />
                            <Link to={'/' + ['p', planId, publicationId].join('/')} className="btn btn-outline-danger">
                                Скасувати
                            </Link>
                        </div>
                    </SimpleCardContainer>
                </div>
            </div>
        </div>
    </>);
}