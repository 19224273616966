import React, {useEffect} from "react";

import PublicationCardProgress from "../common/PublicationCardProgress";
import {Link, useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import PublicationsRequests from "../../../requests/Publications/PublicationsRequests";

import './PublicationEditHeader.css';
import {IconArrowLeft, IconPencil, IconRubberStamp, IconShare, IconX} from "@tabler/icons-react";
import imgPlatformInstagram from "../../../assets/img/upgraded/ig.svg";
import imgPlatformFacebook from "../../../assets/img/upgraded/fb.svg";
import imgPlatformStories from "../../../assets/img/upgraded/instagram-stories.svg";
import imgBlank from "../../../assets/img/blank.png";
import PublicationBadges from "../common/PublicationBadges";
import Grades from "../../../helpers/Grades";
import SimpleModal from "../../ui/SimpleModal";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import {useNotifications} from "../../../providers/NotificationsProvider";
import PublicationEditStatus from "../status/PublicationEditStatus";
import PublicationPlanShortInsights from "../publicationPlans/insights/PublicationPlanShortInsights";
import PublicationShortInsights from "../insights/PublicationShortInsights";
import dayjs from "dayjs";


export default function PublicationEditHeader({publication = {}, onPublicationChanged}) {
    const {planId} = useParams();
    const notificationsContext = useNotifications();
    const apiContext = useApi();
    const publicationsRequests = new PublicationsRequests(apiContext.api);

    const [publishModalVisible, setPublishModalVisible] = React.useState(false);
    const [underReviewModalVisible, setUnderReviewModalVisible] = React.useState(false);
    const [scheduledModalVisible, setScheduledModalVisible] = React.useState(false);
    const [loading, setLoading] = React.useState('');


    const getPlatformIcon = () => {
        switch (publication.platform) {
            case 'instagram':
                return imgPlatformInstagram;
            case 'facebook':
                return imgPlatformFacebook;
            case 'stories':
                return imgPlatformStories;
            default:
                return imgBlank;
        }
    }


    const onUpgradeStatus = () => {
        if (publication.status === 'draft') {
            setUnderReviewModalVisible(true);
        } else if (publication.status === 'under_review') {
            setPublishModalVisible(true);
        } else if (publication.status === 'scheduled') {
            setScheduledModalVisible(true);
        }
    }


    const onPutUnderReview = () => {
        setLoading('put-under-review');

        publicationsRequests.update(publication.id, {status: 'under_review'}).then((response) => {
            if (response !== false) {
                onPublicationChanged();

                notificationsContext.notify('Публікацію поставлено на узгодження.', 'success');
            }
        }).finally(() => {
            setLoading('');
            setUnderReviewModalVisible(false);
        });
    }


    const onPublishNow = () => {
        setLoading('publishing');

        publicationsRequests.publish(publication.id).then((response) => {
            if (response !== false) {
                onPublicationChanged();

                notificationsContext.notify('Публікацію успішно опубліковано!', 'success');
            }
        }).finally(() => {
            setLoading('');
            setPublishModalVisible(false);
        });
    }


    const onSchedulePublish = () => {
        setLoading('scheduling');

        publicationsRequests.schedule(publication.id).then((response) => {
            if (response !== false) {
                onPublicationChanged();

                notificationsContext.notify('Публікацію успішно заплановано!', 'success');
            }
        }).finally(() => {
            setLoading('');
            setPublishModalVisible(false);
        });
    }


    const onCancelScheduled = () => {
        setLoading('cancel-scheduled');

        publicationsRequests.update(publication.id, {status: 'draft'}).then((response) => {
            if (response !== false) {
                onPublicationChanged();

                notificationsContext.notify('Публікацію успішно відмінено.', 'success');
            }
        }).finally(() => {
            setLoading('');
            setScheduledModalVisible(false);
        });
    }


    return publication.id ? (<div className="publication-edit-header">
        <div className="back-button-container">
            <Link to={'/' + ['p', planId].join('/')}
                  className="btn btn-sm btn-light-primary text-primary justify-content-center align-items-center px-0 w-auto">
                <IconArrowLeft size={20} stroke={2}/>
            </Link>
        </div>
        <div className="header-container">
            <div className="publication-info-container">
                <div className="publication-info-container-top">
                    <div className="publication-info-title">
                        <h5 className="publication-info-subheader">
                            <img src={getPlatformIcon()} alt={publication.platform+' icon'} className="img-icon"/>
                            Тема публікації
                        </h5>

                        <h3 className="publication-info-theme">
                            {publication && publication.theme ? (
                                <span><Link to={'/'+['p',planId,publication.id,'edit'].join('/')} className="link-dark">{publication.theme.title}</Link></span>
                            ) : (
                                <span className="placeholder-glow" style={{minWidth: '200px'}}><span className="placeholder placeholder-lg col-12"></span></span>
                            )}

                            <div className="publication-info-theme-toolbar">
                                <Link to="#">
                                    <IconShare size={20} stroke={2}/>
                                </Link>

                                <Link to={'/'+['p',planId,publication.id,'edit'].join('/')}>
                                    <IconPencil size={20} stroke={2}/>
                                </Link>
                            </div>
                        </h3>

                        <div className="publication-info-date text-muted fs-3">{Grades.getDateSinceFormatted(publication.scheduled_for, null, true, true)}</div>
                    </div>

                    <PublicationBadges
                        className="publication-info-badges"
                        publication={publication}
                    />
                </div>

                {publication.notes ? (
                    <div className="publication-info-notes">
                        <h5>Нотатки до публікації</h5>

                        <div className="">{publication.notes}</div>
                    </div>
                ) : ''}
            </div>
            {publication.status !== 'published' ? (
            <div className="warnings-container">
                {(publication.status === 'scheduled') && publication.published_data && (publication.published_data.status === 'PENDING') ? (
                    <div className="publication-card-progress">
                        Матеріал обробляється платформою. Після публікації ви зможете переглянути результат.
                    </div>
                ) : (
                    <PublicationCardProgress
                        publication={publication}
                        className="w-100"
                        onUpgradeStatus={onUpgradeStatus}
                    />
                )}
            </div>
            ) : (
            <div className="d-flex flex-column">
                <PublicationShortInsights />
            </div>
            )}
        </div>

        <SimpleModal
            headerTitle="Підтвердження публікації"
            visible={underReviewModalVisible}
            onClose={() => setUnderReviewModalVisible(false)}
        >
            <div className="mb-4">
                Заплановано на {Grades.getDateSinceFormatted(publication.scheduled_for, null, true)}.<br />
                Платформа: {publication.platforms[publication.platform]}.
            </div>

            <div className="d-flex flex-wrap gap-3">
                <ButtonWithLoading
                    label={(loading !== 'put-under-review') ? (<><IconRubberStamp size={20} stroke={2} className="me-2" />Поставити на узгодження</>) : 'Поставити на узгодження'}
                    className="btn btn-primary"
                    iconClassName="d-none"
                    onClick={onPutUnderReview}
                    loading={loading === 'put-under-review'}
                />
            </div>
        </SimpleModal>

        <SimpleModal
            headerTitle="Підтвердження публікації"
            visible={publishModalVisible}
            onClose={() => setPublishModalVisible(false)}
        >
            <div className="mb-4">
                Буде опубліковано {Grades.getDateSinceFormatted(publication.scheduled_for, null, true)}.<br />
                Платформа: {publication.platforms[publication.platform]}.
            </div>

            <div className="d-flex flex-wrap gap-3">
                {dayjs(publication.scheduled_for).isAfter(dayjs().add(11, 'minutes')) ? (
                <ButtonWithLoading
                    label="Запланувати"
                    className="btn btn-success"
                    iconClassName="d-none"
                    onClick={onSchedulePublish}
                    loading={loading === 'scheduling'}
                />
                ) : ''}
                <ButtonWithLoading
                    label="Опублікувати зараз"
                    className="btn btn-outline-warning"
                    iconClassName="d-none"
                    onClick={onPublishNow}
                    loading={loading === 'publishing'}
                />
            </div>

            {!dayjs(publication.scheduled_for).isAfter(dayjs().add(11, 'minutes')) ? (
            <div className="fs-2 text-muted mt-3">Дозволено планувати публікації не пізніше, ніж за 10 хвилин до дати публікації.</div>
            ) : ''}
        </SimpleModal>

        <SimpleModal
            headerTitle="Відміна публікації"
            visible={scheduledModalVisible}
            onClose={() => setScheduledModalVisible(false)}
        >
            <div className="mb-4">
                Мало бути опубліковано {Grades.getDateSinceFormatted(publication.scheduled_for, null, true)}.<br />
                Платформа: {publication.platforms[publication.platform]}.
            </div>

            <div className="d-flex flex-wrap gap-3">
                <ButtonWithLoading
                    label={(loading !== 'cancel-scheduled') ? (<><IconX size={20} stroke={2} className="me-2" />Відмінити публікацію</>) : 'Відмінити публікацію'}
                    className="btn btn-danger"
                    iconClassName="d-none"
                    onClick={onCancelScheduled}
                    loading={loading === 'cancel-scheduled'}
                />
            </div>
        </SimpleModal>
    </div>) : null;
}