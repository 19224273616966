import React, {useEffect} from "react";
import {useGoogleMapsApi} from "../../providers/googleMapsApiProvider";

function AddressAutocomplete(props) {
    const [value, setValue] = React.useState(props.value);
    const autocompleteInput = React.createRef();
    const googleMapsApiContext = useGoogleMapsApi();
    

    useEffect(() => {
        if (!window.google || !googleMapsApiContext.gmAPILoaded) {return;}

        let autocompleteOptions = {};
        if (props.types) {autocompleteOptions.types = props.types;}
        if (props.componentRestrictions) {autocompleteOptions.componentRestrictions = props.componentRestrictions;}

        const autocomplete = new window.google.maps.places.Autocomplete(
            autocompleteInput.current,
            autocompleteOptions
        );

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();

            if (place) {
               setValue(place.name);
               props.onChange(place);
            }
        });
    }, [googleMapsApiContext.gmAPILoaded]);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);


    let className = ['form-control'];
    if (props.className) {
        const classNameParts = props.className.split(' ');
        classNameParts.forEach((part) => {
            if (className.indexOf(part) === -1) {
                className.push(part);
            }
        });
    }
    return (
        <input type="text" className={className.join(' ')} value={value} onChange={(e) => {setValue(e.target.value)}} ref={autocompleteInput} />
    );
}

export default AddressAutocomplete;