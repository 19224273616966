import React, {useEffect} from 'react';
import {useApi} from "../../../providers/ApiProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsRequests from "../../../requests/Projects/ProjectsRequests";
import PublicationPlanViewHeader from "./PublicationPlanViewHeader";
import ContentPlansModel from "../../../requests/Publications/PublicationPlansRequests";
import {useParams} from "react-router-dom";

import logoInstagram from './../../../assets/img/instagram.svg';
import logoFacebook from './../../../assets/img/facebook.svg';
import logoStories from './../../../assets/img/stories.svg';
import PublicationCard from "../common/PublicationCard";
import PublicationPlanViewPlatformProgress from "./PublicationPlanViewPlatformProgress";
import PublicationPlanViewChecklist from "./PublicationPlanViewChecklist";
import PublicationCardAdd from "../common/PublicationCardAdd";
import PublicationsRequests from "../../../requests/Publications/PublicationsRequests";



export default function PublicationPlanViewOld(props) {
    const {projectId, contentPlanId} = useParams();
    const apiContext = useApi();
    const notificationsContext = useNotifications();
    const projectsRequests = new ProjectsRequests(apiContext.api);
    const contentPlansModel = new ContentPlansModel(apiContext.api);
    const publicationsRequests = new PublicationsRequests(apiContext.api);

    const [projectLoading, setProjectLoading] = React.useState(true);
    const [contentPlanLoading, setContentPlanLoading] = React.useState(true);
    const [project, setProject] = React.useState({});
    const [contentPlan, setContentPlan] = React.useState({});
    const [publications, setPublications] = React.useState({
        instagram: [],
        facebook: [],
        stories: [],
    });


    useEffect(() => {
        document.title = 'План Land Rover Харків від 7 лютого | Likengale';

        getContentPlanInfo();
    }, []);


    const getProjectInfo = () => {
        return projectsRequests.view(projectId).then((response) => {
            if (response !== false) {
                setProject(response);
            }
        });
    }


    const getContentPlanInfo = () => {
        setContentPlanLoading(true);

        getProjectInfo().then(() => {
            contentPlansModel.view(contentPlanId).then((response) => {
                if (response !== false) {
                    setContentPlan(response);
                }
            }).finally(() => {
                setContentPlanLoading(false);
            });
        });

        getPublications();
    }


    const getPublications = () => {
        publicationsRequests.list({plan_id: contentPlanId}).then((response) => {
            if (response !== false && response.data) {
                let newPublications = {
                    instagram: [],
                    facebook: [],
                    stories: [],
                };

                for (let publication of response.data) {
                    if (['instagram', 'facebook', 'stories'].includes(publication.platform)) {
                        newPublications[publication.platform].push(publication);
                    }
                }

                setPublications(newPublications);
            }
        });
    }


    return (
        <div className="row">
            <div className="col-lg-5 col-xl-4 col-xxl-3">
                <PublicationPlanViewHeader project={project} contentPlan={contentPlan} />
            </div>
            <div className="col-lg-7 col-xl-8 col-xxl-9 pt-1">
                <div className="row">
                    <div className="col-xl-6 col-xxl-4">
                        <div className="d-flex flex-column gap-3 align-items-center justify-content-center m-block-gap">
                            <img src={logoInstagram} className="img-fluid" width={36} height={36} alt={'Instagram'}/>
                            <h5 className={'m-0 fw-semibold'}>Instagram</h5>

                            <PublicationPlanViewPlatformProgress />
                        </div>

                        <PublicationCardAdd />

                        {publications.instagram.map((publication, index) => {
                            return <PublicationCard key={index} publication={publication} />;
                        })}

                        <PublicationPlanViewChecklist />
                    </div>
                    <div className="col-xl-6 col-xxl-4">
                        <div className="d-flex flex-column gap-3 align-items-center justify-content-center m-block-gap">
                            <img src={logoFacebook} className="img-fluid" width={36} height={36} alt={'Facebook'}/>
                            <h5 className={'m-0 fw-semibold'}>Facebook</h5>

                            <PublicationPlanViewPlatformProgress />
                        </div>

                        <PublicationCardAdd />

                        {publications.facebook.map((publication, index) => {
                            return <PublicationCard key={index} publication={publication} />;
                        })}

                        <PublicationPlanViewChecklist />
                    </div>
                    <div className="col-xl-12 col-xxl-4">
                        <div className="d-flex flex-column gap-3 align-items-center justify-content-center m-block-gap">
                            <img src={logoStories} className="img-fluid" width={36} height={36} alt={'Stories'}/>
                            <h5 className={'m-0 fw-semibold'}>Stories</h5>

                            <PublicationPlanViewPlatformProgress />
                        </div>

                        <PublicationCardAdd />

                        {publications.stories.map((publication, index) => {
                            return <PublicationCard key={index} publication={publication} />;
                        })}

                        <PublicationPlanViewChecklist />
                    </div>
                </div>
            </div>
        </div>
    );
}
