import React, {useEffect, useRef} from 'react';
import FormTextArea from "../../layout/ui/FormTextArea";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import PublicationEditCaptionGenerateForm from "./PublicationEditCaptionGenerateForm";

import './PublicationEditCaption.css';
import PublicationVersionsRequests from "../../../requests/Publications/PublicationVersionsRequests";
import {useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import {useMeta} from "../../../providers/MetaProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsRequests from "../../../requests/Projects/ProjectsRequests";
import PublicationPlansRequests from "../../../requests/Publications/PublicationPlansRequests";
import PublicationsRequests from "../../../requests/Publications/PublicationsRequests";
import {IconAlien, IconArrowNarrowLeft, IconArrowNarrowLeftDashed, IconEye, IconMoodTongueWink2, IconX} from "@tabler/icons-react";
import EmojiPicker from "emoji-picker-react";


export default function PublicationEditCaption({publication, onCaptionChanged}) {
    const apiContext = useApi();
    const notificationsContext = useNotifications();
    const publicationVersionsModel = new PublicationVersionsRequests(apiContext.api, publication.id);

    const [loading, setLoading] = React.useState(false);
    const [emojiPickerOpen, setEmojiPickerOpen] = React.useState(false);
    const [caption, setCaption] = React.useState(publication && publication.latestVersion && publication.latestVersion.text ? publication.latestVersion.text : '');
    const [originalCaption, setOriginalCaption] = React.useState('');

    const suggestions = [
        'Обов\'язково вказати ціну на авто в наявності чи ціну «від ...» для моделі загалом',
        'Перелічити все платне додаткове обладнання',
        'Згадати про можливості кредитування чи лізингу',
        'Згадати про тест-драйв, якщо модель доступна для тестування',
    ];

    const emojis = ['😊', '◾', '⭐', '✅', '❗', '❄️', '🚙', '✨', '🔥', '⚒️', '✉️', '➡️', '⬆️', '❌', '❤️', '📲', '☎️', '1️⃣', '2️⃣', '3️⃣', '4️⃣', '5️⃣', '6️⃣', '7️⃣', '8️⃣', '9️⃣', '0️⃣'];
    const inputRef = useRef(null);


    const handleEmojiClick = (emoji) => {
        // Get current cursor position
        const cursorPosition = inputRef.current.selectionStart;

        // Split text into before and after cursor
        const beforeCursor = caption.slice(0, cursorPosition);
        const afterCursor = caption.slice(cursorPosition);

        // Insert emoji at cursor position
        const newText = beforeCursor + emoji + afterCursor;
        setCaption(newText);

        // Set cursor position after the inserted emoji
        setTimeout(() => {
            inputRef.current.selectionStart = cursorPosition + emoji.length;
            inputRef.current.selectionEnd = cursorPosition + emoji.length;
        }, 0);
    };



    useEffect(() => {
        if (publication && publication.latestVersion && !publication.latestVersion.preview) {
            setOriginalCaption(publication.latestVersion.text ? publication.latestVersion.text : '');
        }

        refreshCaption();
    }, [publication]);


    const refreshCaption = () => {
        if (publication && publication.latestVersion) {
            setCaption(publication.latestVersion.text ? publication.latestVersion.text : '');
        } else {
            setCaption('');
        }
    }


    const onSave = () => {
        setLoading(true);

        const data = {
            publication_id: publication.id,
            text: caption
        };

        publicationVersionsModel.create(data).then((response) => {
            if (response !== false) {
                notificationsContext.notify('Підпис до публікації успішно збережено.', 'success');

                onCaptionChanged(response);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const onPreview = () => {
        let mutatedCaption = publication.latestVersion;
        mutatedCaption.text = caption;
        mutatedCaption.preview = true;

        onCaptionChanged(mutatedCaption);
    }


    return (<>
        <h4 className="fs-5 fw-semibold mt-0 mb-3">
            Підпис до публікації
            {(originalCaption !== caption) && (
            <sup className="text-danger fw-normal fs-1 ms-3">Зміни не збережено</sup>
            )}
        </h4>
        <div className="row gap-4 gap-xl-0">
            <div className="col-xl-5 order-1 order-xl-0">
                <div className="form-group mb-2">
                    <textarea
                        id="cf-caption"
                        ref={inputRef}
                        className="form-control"
                        value={caption ? caption : ''}
                        onChange={(e) => setCaption(e.target.value)}
                        rows={16}
                    />
                </div>

                <div className="d-flex justify-content-start flex-wrap gap-1 mb-3">
                    {emojis.map((item, key) => (
                        <button
                            key={key}
                            onClick={() => handleEmojiClick(item)}
                            className="btn btn-circle btn-sm fs-4 align-items-center justify-content-center"
                        >
                            {item}
                        </button>
                    ))}
                </div>

                <div className="d-flex justify-content-between gap-3">
                    <div className="d-flex justify-content-start gap-3">
                        <button className="btn btn-icon btn-light-primary text-primary" onClick={onPreview}>
                            <IconEye size={20} stroke={2} className="d-inline-block d-md-none"/>
                            <span className="d-none d-md-flex align-items-center"><IconArrowNarrowLeftDashed size={20} stroke={2}
                                                                                                             className="me-2"/>Прев'ю</span>
                        </button>

                        <button className="btn btn-icon btn-light-warning text-warning d-none" onClick={() => setEmojiPickerOpen(!emojiPickerOpen)}>
                            {emojiPickerOpen ? (<IconX size={20} stroke={2} />) : (<IconAlien size={20} stroke={2} />)}
                        </button>
                    </div>
                    <ButtonWithLoading
                        label={'Зберегти'}
                        className={'btn-primary'}
                        onClick={onSave}
                        iconClassName={"d-none"}
                        loading={loading}
                    />
                </div>
            </div>
            <div className="col-xl-7">
                <div className="mb-4">
                    <h4 className="fs-3 text-uppercase text-muted fw-semibold d-flex align-items-center"><i className="ti ti-info-circle fs-4 me-2 d-none"></i>Побажання до тексту</h4>

                    {suggestions.length > 0 && (
                    <div className="d-flex flex-column gap-2">
                        {suggestions.map((s, i) => (
                            <div key={i} className="d-flex gap-2 align-items-start fs-4">
                                <i className="ti ti-checks fs-4 mt-1"></i>
                                <span className="fs-3">{s}</span>
                            </div>
                        ))}
                    </div>
                    )}
                </div>

                <PublicationEditCaptionGenerateForm
                    publication={publication}
                    onTextGenerated={(text) => setCaption(text)}
                />
            </div>
        </div>
    </>);
}