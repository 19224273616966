import React from 'react';
import {IconShare, IconPencil} from '@tabler/icons-react';
import {Link} from "react-router-dom";
import AudienceNavHeader from "./AudienceNavHeader";


export default function AudienceNav({audience = {}}) {



    return (
        <nav className="section-nav mb-0">
            <AudienceNavHeader />

            <div className="section-description">
                <h2>
                    <div className="section-text">ЖК, котеджні комплекси та інші житлові об’єкти</div>

                    <div className="section-toolbar">
                        <Link to="/p">
                            <IconShare size={20} stroke={2} />
                        </Link>

                        <Link to={'/'+['p','edit'].join('/')}>
                            <IconPencil size={20} stroke={2} />
                        </Link>
                    </div>
                </h2>
                <div className="section-subtitle">{true ? ('Розмір аудиторії: 100 000 – 300 000') : (
                    <div className="placeholder-glow">
                        <span className="placeholder col-4" style={{maxWidth: '300px'}}></span>
                    </div>
                )}</div>
            </div>
        </nav>
    );
}