export default class StrHelper {
    static nl2p(str, className = '', prefix = null) {
        return str.split('\n').map((item, key, lines) => {
            return <p key={key} className={(key === (lines.length - 1) ? 'mb-0 ' : '') + className}>
                {prefix ? prefix : ''}
                {item}
            </p>
        });
    }


    /**
     * Format number to string with words
     *
     * @param {number} num
     * @param {string[]} words e.g. ['день', 'дні', 'днів']
     * @returns {string}
     */
    static wordForm(num, words) {
        let cases = [2, 0, 1, 1, 1, 2];
        return words[(num % 100 > 4 && num % 100 < 20) ? 2 : cases[(num % 10 < 5) ? num % 10 : 5]];
    }
}