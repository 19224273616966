import BaseCrudRequests from "../BaseCrudRequests";

/**
 * Publications requests
 *
 * @param {ApiHelper} api
 */
export default class PublicationsRequests extends BaseCrudRequests {
	baseUrl = '/publications';

	constructor(api) {
		super(api);
	}


	async coreEfficiency(params = {}, errorHandler) {
		let url = this.baseUrl + '/core-efficiency';
		if (Object.keys(params).length) {
			url += '?' + new URLSearchParams(params).toString();
		}

		const response = await this.api.getRequest(url);

		return this.processResponse(response, errorHandler);
	}


	async examine(pk, errorHandler) {
		let url = this.baseUrl + '/' + pk + '/examine';

		const response = await this.api.postRequest(url);

		return this.processResponse(response, errorHandler);
	}


	async publish(pk, errorHandler) {
		let url = this.baseUrl + '/' + pk + '/publish';

		const response = await this.api.postRequest(url);

		return this.processResponse(response, errorHandler);
	}


	async schedule(pk, errorHandler) {
		let url = this.baseUrl + '/' + pk + '/schedule';

		const response = await this.api.postRequest(url);

		return this.processResponse(response, errorHandler);
	}
}