import React from 'react';
import {IconShare, IconPencil} from '@tabler/icons-react';
import {Link} from "react-router-dom";
import SimpleBar from "simplebar-react";
import HeaderNotifications from "./ui/HeaderNotifications";
import Grades from "../../helpers/Grades";
import SectionNavHeader from "./SectionNavHeader";


export default function SectionNav({plan = {}, displayEdit = true}) {



    return (
        <nav className="section-nav">
            <SectionNavHeader />

            <div className="section-description">
                <h2>
                    <div className="section-text">
                        <Link to={'/'+['p',plan.id].join('/')} className="link-dark">
                            План на тиждень
                        </Link>
                    </div>

                    <div className="section-toolbar">
                        <HeaderNotifications />

                        <Link to="/p">
                            <IconShare size={20} stroke={2} />
                        </Link>

                        {displayEdit && (
                        <Link to={'/'+['p',plan.id,'edit'].join('/')}>
                            <IconPencil size={20} stroke={2} />
                        </Link>
                        )}
                    </div>
                </h2>
                <div className="section-subtitle">{plan && plan.date_since ? ('від ' + Grades.getDateSinceFormatted(plan.date_since)) : (
                    <div className="placeholder-glow">
                        <span className="placeholder col-4" style={{maxWidth: '300px'}}></span>
                    </div>
                )}</div>
            </div>
        </nav>
    );
}