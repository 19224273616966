import {createBrowserRouter, Outlet} from "react-router-dom";
import App from "./App";
import {RequireAuth, RequireProject} from "./providers/AuthProvider";
import Auth from "./components/auth/Auth";
import Login from "./components/auth/Login";
import React from "react";
import ForgotPassword from "./components/auth/ForgotPassword";
import Error404 from "./components/errors/Error404";
import Logout from "./components/auth/Logout";
import MainLayout from "./components/layout/MainLayout";
import Faq from "./components/common/Faq";
import ForgotPasswordConfirm from "./components/auth/ForgotPasswordConfirm";
import Register from "./components/auth/Register";
import Planning from "./components/common/planning/Planning";
import PlanningCover from "./components/common/planning/PlanningCover";
import PublicationViewInsights from "./components/publications/publications/PublicationViewInsights";
import ProjectsCreate from "./components/projects/projects/ProjectsCreate";
import ProjectsEdit from "./components/projects/projects/ProjectsEdit";
import ProjectsList from "./components/projects/projects/ProjectsList";
import PlanningProject from "./components/common/planning/PlanningProject";
import PublicationPlanViewOld from "./components/publications/publicationPlans/PublicationPlanView-old";
import Project from "./components/projects/view/Project";
import PublicationEdit from "./components/publications/edit/PublicationEdit";
import PublicationsPlansList from "./components/publications/publicationPlans/list/PublicationsPlansList";
import PublicationPlanView from "./components/publications/publicationPlans/PublicationPlanView";
import PublicationsPlanEdit from "./components/publications/publicationPlans/edit/PublicationsPlanEdit";
import PublicationEditForm from "./components/publications/edit/PublicationEditForm";
import ProjectTasksList from "./components/projects/tasks/ProjectTasksList";
import ProjectTasksView from "./components/projects/tasks/ProjectTasksView";
import ProjectTasksEdit from "./components/projects/tasks/ProjectTasksEdit";
import ProjectTasksCreate from "./components/projects/tasks/ProjectTasksCreate";
import FacebookCallback from "./components/auth/FacebookCallback";
import ProjectsCreateFacebook from "./components/projects/projects/ProjectsCreateFacebook";
import ThemesList from "./components/projects/themes/ThemesList";
import ThemesCreate from "./components/projects/themes/ThemesCreate";
import ThemesEdit from "./components/projects/themes/ThemesEdit";
import Audience from "./components/audiences/view/Audience";
import ProjectsEditReconnect from "./components/projects/projects/ProjectsEditReconnect";

export const router = createBrowserRouter([
    {
        path: "/",
        Component: App,
        children: [
            {
                index: true,
                element: (<RequireAuth><RequireProject><MainLayout><Project /></MainLayout></RequireProject></RequireAuth>),
            },
            {
                path: "themes",
                element: (<RequireAuth><RequireProject><MainLayout><Outlet /></MainLayout></RequireProject></RequireAuth>),
                children: [
                    {
                        index: true,
                        Component: ThemesList,
                    },
                    {
                        path: "create",
                        Component: ThemesCreate,
                    },
                    {
                        path: ":themeId",
                        Component: ThemesEdit,
                    },
                ],
            },
            {
                path: "promo",
                element: (<RequireAuth><RequireProject><MainLayout><Outlet /></MainLayout></RequireProject></RequireAuth>),
                children: [
                    {
                        index: true,
                        Component: Audience,
                    },
                ],
            },
            {
                path: "p",
                element: (<RequireAuth><RequireProject><MainLayout><Outlet /></MainLayout></RequireProject></RequireAuth>),
                children: [
                    {
                        index: true,
                        Component: PublicationsPlansList,
                    },
                    {
                        path: "tasks",
                        children: [
                            {
                                index: true,
                                Component: ProjectTasksList,
                            },
                            {
                                path: "create",
                                Component: ProjectTasksCreate,
                            },
                            {
                                path: ":taskId",
                                children: [
                                    {
                                        path: "edit",
                                        Component: ProjectTasksEdit,
                                    },
                                    {
                                        index: true,
                                        Component: ProjectTasksView,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: ":planId",
                        children: [
                            {
                                path: ":publicationId",
                                children: [
                                    {
                                        path: "insights",
                                        Component: PublicationViewInsights,
                                    },
                                    {
                                        path: "edit",
                                        Component: PublicationEditForm,
                                    },
                                    {
                                        index: true,
                                        Component: PublicationEdit,
                                    },
                                ]
                            },
                            {
                                path: "edit",
                                Component: PublicationsPlanEdit,
                            },
                            {
                                index: true,
                                Component: PublicationPlanView,
                            },
                        ],
                    },
                ],
            },
            {
                path: "auth",
                Component: Auth,
                children: [
                    {
                        path: "login",
                        Component: Login,
                    },
                    {
                        path: "register",
                        Component: Register,
                    },
                    {
                        path: "forgot-password",
                        children: [
                            {
                                path: "confirm",
                                Component: ForgotPasswordConfirm,
                            },
                            {
                                index: true,
                                Component: ForgotPassword,
                            }
                        ],
                    },
                    {
                        path: "facebook",
                        children: [
                            {
                                path: "regular",
                                Component: FacebookCallback,
                            },
                        ],
                    },
                    {
                        path: "logout",
                        Component: Logout,
                    },
                ],
            },
            {
                path: "projects",
                element: (<RequireAuth><MainLayout><Outlet /></MainLayout></RequireAuth>),
                children: [
                    {
                        path: "create",
                        children: [
                            {
                                path: "facebook",
                                Component: ProjectsCreateFacebook,
                            },
                            {
                                index: true,
                                Component: ProjectsCreate,
                            },
                        ]
                    },
                    {
                        path: ":projectId",
                        children: [
                            {
                                index: true,
                                Component: ProjectsEdit,
                            },
                        ]
                    },
                    {
                        path: 'reconnect-facebook',
                        Component: ProjectsEditReconnect,
                    },
                    {
                        index: true,
                        Component: ProjectsList,
                    },
                ],
            },
            {
                path: "plan-old",
                element: (<RequireAuth><PlanningCover /></RequireAuth>),
                children: [
                    {
                        index: true,
                        Component: Planning,
                    },
                    /*{
                        path: "create",
                        Component: BrandsCreate,
                    },*/
                    {
                        path: ":projectId",
                        children: [
                            {
                                path: ":contentPlanId",
                                children: [
                                    {
                                        path: ":publicationId",
                                        children: [
                                            {
                                                path: "insights",
                                                Component: PublicationViewInsights,
                                            },
                                            {
                                                index: true,
                                                Component: PublicationEdit,
                                            },
                                        ]
                                    },
                                    {
                                        index: true,
                                        Component: PublicationPlanViewOld,
                                    },
                                ],
                            },
                            {
                                index: true,
                                Component: PlanningProject,
                            },
                        ]
                    },
                ],
            },
            {
                path: "faq",
                element: (<RequireAuth><MainLayout><Faq /></MainLayout></RequireAuth>),
            },
        ],
    },
    {
        path: "*",
        Component: Error404,
    }
]);
