import dayjs from "dayjs";

export default class Grades {
    static defaultGrading = {
        10: {color: "#2A3547", className: "dark"},
        50: {color: "#fa896b", className: "danger"},
        85: {color: "#ffae1f", className: "warning"},
        105: {color: "#13DEB9", className: "success"},
        'default': {color: "#5D87FF", className: "primary"}
    };


    /**
     * Get color by grade
     * @param grade
     * @returns {string}
     */
    static getClassNameByGrade = (grade) => {
        const grading = Grades.defaultGrading;

        let className = grading['default'].className;
        for (let key in grading) {
            if (grade < key) {
                className = grading[key].className;
                break;
            }
        }

        return className;
    }


    /**
     * Get icon by type
     *
     * @param type
     * @returns {string}
     */
    static getIconByType = (type) => {
        let icon = 'music';

        switch (type) {
            case 'informational':
                icon = 'school';
                break;
            case 'sales':
                icon = 'tags';
                break;
            case 'entertainment':
                icon = 'music';
                break;
        }

        return icon;
    }


    /**
     * Get icon class name by type
     *
     * @param type
     * @returns {string}
     */
    static getIconClassNameByType = (type) => {
        let className = 'dark';

        switch (type) {
            case 'informational':
                className = 'primary';
                break;
            case 'sales':
                className = 'dark';
                break;
            case 'entertainment':
                className = 'danger';
                break;
        }

        return className;
    }


    /**
     * Convert GMT to local time as DD MMM + YYYY if year differs from today's
     *
     * @param value
     * @param format
     * @param withTime
     * @param withDayOfWeek
     * @returns {string}
     */
    static getDateSinceFormatted = (value, format = null, withTime = false, withDayOfWeek = false) => {
        if (!value) {
            return '';
        }
        let dateOptions;

        const date = dayjs(value);
        const today = dayjs();
        if (format) {
            dateOptions = format;
        } else {
            dateOptions = 'D MMMM YYYY року';

            if (date.year() === today.year()) {
                dateOptions = 'D MMMM';
            }
        }

        if (withTime) {
            dateOptions += ' о HH:mm';
        }

        if (withDayOfWeek) {
            dateOptions = 'dddd, ' + dateOptions;
        }

        return date.locale('uk').format(dateOptions);

        /*const date = new Date(value);
        const today = new Date();
        if (format) {
            dateOptions = format;
        } else {
            dateOptions = {day: 'numeric', month: 'long', year: 'numeric'};

            if (date.getFullYear() === today.getFullYear()) {
                dateOptions = {day: 'numeric', month: 'long'};
            }
        }

        if (withTime) {
            dateOptions['hour'] = 'numeric';
            dateOptions['minute'] = 'numeric';
        }

        if (withDayOfWeek) {
            dateOptions['weekday'] = 'long';
        }

        return date.toLocaleDateString('uk-UA', dateOptions);*/
    }
}