import React, { useEffect } from 'react';
import {
    IconInfoSquareRounded, IconMessage, IconPlus
} from "@tabler/icons-react";
import LineChartPlan from "../../ui/charts/LineChartPlan";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import ProjectTitleExtended from "../common/ProjectTitleExtended/ProjectTitleExtended";
import ProjectEfficiencyCard from "../common/ProjectEfficiencyCard";
import ProjectsRequests from "../../../requests/Projects/ProjectsRequests";
import { useApi } from "../../../providers/ApiProvider";
import { useMeta } from "../../../providers/MetaProvider";
import CompetitorMetricsPreview from "../../common/CompetitorMetricsPreview/CompetitorMetricsPreview";
import { useAuth } from "../../../providers/AuthProvider";
import PublicationsPlanPreview from "../../publications/publicationPlans/preview/PublicationsPlanPreview";
import SectionNavHeader from "../../layout/SectionNavHeader";


export default function Project({ }) {
    const apiContext = useApi();
    const authContext = useAuth();
    const metaContext = useMeta();
    const projectEssentials = authContext.project;
    const projectsRequests = new ProjectsRequests(apiContext.api);

    const [project, setProject] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        document.title = projectEssentials.title + ' | Likengale';

        getProject();
    }, []);


    useEffect(() => {
        if (project.id) {
            metaContext.update({
                title: project.title
            });
        }
    }, [project]);


    const getProject = () => {
        setLoading(true);

        projectsRequests.view(projectEssentials.id).then((response) => {
            if (response !== false) {
                setProject(response);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const visibilityDummyData = {
        current: [{ x: 0, y: 0 }, { x: 5, y: 0.3 }, { x: 10, y: 0.7 }, { x: 15, y: 1.35 }, { x: 20, y: 1.9 }, { x: 25, y: 2.7 }, { x: 30, y: 2.9 }, { x: 35, y: 4.1 }, {
            x: 40,
            y: 4.9
        }, { x: 45, y: 6.4 }, { x: 50, y: 7.5 }, { x: 55, y: 9.1 }, { x: 60, y: 9.5 }],
        average: [{ x: 0, y: 0 }, { x: 5, y: 0.7 }, { x: 10, y: 1.6 }, { x: 15, y: 3.1 }, { x: 20, y: 4.2 }, { x: 25, y: 4.9 }, { x: 30, y: 5.8 }, { x: 35, y: 6 }, {
            x: 40,
            y: 8.5
        }, { x: 45, y: 8.9 }, { x: 50, y: 9.9 }, { x: 55, y: 10.5 }, { x: 60, y: 11.2 }],
        best: [{ x: 0, y: 0 }, { x: 5, y: 1 }, { x: 10, y: 2 }, { x: 15, y: 4 }, { x: 20, y: 5 }, { x: 25, y: 7 }, { x: 30, y: 9 }, { x: 35, y: 10 }, { x: 40, y: 11 }, {
            x: 45,
            y: 11.5
        }, { x: 50, y: 12.9 }, { x: 55, y: 13.5 }, { x: 60, y: 17.9 }],
        expected: [{ x: 0, y: 0 }, { x: 60, y: 8 }],
    };

    const efficiencyDummyData = {
        current: [{ x: 0, y: 0 }, { x: 5, y: 0.3 }, { x: 10, y: 0.7 }, { x: 15, y: 1.35 }, { x: 20, y: 1.9 }, { x: 25, y: 2.7 }, { x: 30, y: 2.9 }, { x: 35, y: 4.1 }, {
            x: 40,
            y: 4.9
        }, { x: 45, y: 6.4 }, { x: 50, y: 7.5 }, { x: 55, y: 9.1 }, { x: 60, y: 9.5 }],
        average: [{ x: 0, y: 0 }, { x: 5, y: 0.7 }, { x: 10, y: 1.6 }, { x: 15, y: 3.1 }, { x: 20, y: 4.2 }, { x: 25, y: 4.9 }, { x: 30, y: 5.8 }, { x: 35, y: 6 }, {
            x: 40,
            y: 8.5
        }, { x: 45, y: 8.9 }, { x: 50, y: 9.9 }, { x: 55, y: 10.5 }, { x: 60, y: 11.2 }],
        best: [{ x: 0, y: 0 }, { x: 5, y: 1 }, { x: 10, y: 2 }, { x: 15, y: 4 }, { x: 20, y: 5.2 }, { x: 25, y: 7 }, { x: 30, y: 9 }, { x: 35, y: 10 }, { x: 40, y: 11 }, {
            x: 45,
            y: 11.5
        }, { x: 50, y: 12.9 }, { x: 55, y: 13.5 }, { x: 60, y: 17.9 }],
        expected: [{ x: 0, y: 0 }, { x: 60, y: 8 }],
    }


    return (
        <>
            <ProjectTitleExtended project={project} loading={loading} />

            <div className="d-flex flex-column block-gap mt-st">
                <div className="row block-gutter">
                    <div className="col-xl-8">
                        <nav className="section-nav mb-0 pt-0">
                            <SectionNavHeader />
                        </nav>


                        <PublicationsPlanPreview />

                        <SimpleBar autoHide={true} className="opacity-25">
                            <div className="d-flex mb-st">
                                <button className="btn btn-sm btn-light-primary text-primary">Охоплення</button>
                                <button className="btn btn-sm text-primary">Підписники</button>
                                <button className="btn btn-sm text-primary">Видимість</button>
                                <button className="btn btn-sm text-primary">Залученість</button>
                                <button className="btn btn-sm text-primary">Витрати</button>
                            </div>
                        </SimpleBar>

                        <div className="line-chart-noted full-width active mb-double-st opacity-25">
                            <div className="line-chart-noted-title">
                                <h3>
                                    <div className="title-text">
                                        Органічне охоплення
                                        <a href="#"><IconInfoSquareRounded size={20} stroke={2} /></a>
                                    </div>
                                </h3>

                                <p className="chart-description">Загальна кількість унікальних охоплених акаунтів для профілів та публікацій, здійснена
                                    органічними засобами.</p>
                            </div>

                            <LineChartPlan
                                data={visibilityDummyData}
                            />
                        </div>

                        <ProjectEfficiencyCard
                            project={project}
                            loading={loading}
                            cardClassName="m-block-gap opacity-25"
                        />
                    </div>
                    <div className="col-xl-4">
                        <div className="mb-double-st opacity-25">
                            <div className="text-center mb-4">
                                <h3 className="lh-1 m-0 mb-1">Конкуренти</h3>
                                <div className="text-muted fs-2">за період 14 жовтня – 12 листопада</div>
                                <button className="btn btn-sm mx-auto btn-link">Відкрити дайджест</button>
                            </div>

                            <div className="d-flex flex-column gap-4">
                                <CompetitorMetricsPreview
                                    asTitle={true}
                                />

                                <CompetitorMetricsPreview
                                    id="citroen.kh.ua"
                                    title="Пежо на Гагаріна"
                                    logo=""
                                    subscribersCount={21132}
                                    commentsCount={14}
                                    commentsPercentage={100}
                                    likesCount={25}
                                    likesPercentage={100}
                                />

                                <CompetitorMetricsPreview
                                    id="citroen.kh.ua"
                                    title="Сітроен на Гагаріна"
                                    logo=""
                                    subscribersCount={14764}
                                    commentsCount={9}
                                    commentsPercentage={9 / 14 * 100}
                                    likesCount={19}
                                    likesPercentage={19 / 25 * 100}
                                />

                                <CompetitorMetricsPreview
                                    id="citroen.kh.ua"
                                    title="Опель на Гагаріна"
                                    logo=""
                                    subscribersCount={7931}
                                    commentsCount={5}
                                    commentsPercentage={5 / 14 * 100}
                                    likesCount={8}
                                    likesPercentage={8 / 25 * 100}
                                />

                                <CompetitorMetricsPreview
                                    asAddButton={true}
                                />
                            </div>
                        </div>

                        <div className="mb-double-st opacity-25">
                            <h3 className="align-items-center d-flex gap-3 lh-1">
                                Джерела інформації

                                <Link to={'/'} className="align-items-center btn btn-outline-success btn-sm d-inline-flex px-1">
                                    <IconPlus size={16} stroke={2} />
                                </Link>
                            </h3>
                            <p className="text-muted fs-2 mb-4">Оберіть джерела інформації, які ми будемо аналізувати в пошуках цікавих подій та ідей для
                                публікацій.</p>

                            <div className="d-flex flex-column gap-4">
                                <div className="d-flex flex-row gap-2">
                                    <div className="bg-muted" style={{ width: '36px', height: '36px' }}></div>
                                    <div>
                                        <h6 className="m-0">Сайт імпортера</h6>
                                        <p className="m-0 mb-2 fs-2 text-muted">опрацьовано 18 листопада, 16:42</p>

                                        <Link to={'/'} className="d-flex align-items-center gap-1 fw-bold fs-2">
                                            <IconMessage size={20} stroke={2} /> 12
                                        </Link>
                                    </div>
                                </div>

                                <div className="d-flex flex-row gap-2">
                                    <div className="bg-muted" style={{ width: '36px', height: '36px' }}></div>
                                    <div>
                                        <h6 className="m-0">Сайт імпортера</h6>
                                        <p className="m-0 mb-2 fs-2 text-muted">опрацьовано 18 листопада, 16:42</p>

                                        <Link to={'/'} className="d-flex align-items-center gap-1 fw-bold fs-2">
                                            <IconMessage size={20} stroke={2} /> 12
                                        </Link>
                                    </div>
                                </div>

                                <div className="d-flex flex-row gap-2">
                                    <div className="bg-muted" style={{ width: '36px', height: '36px' }}></div>
                                    <div>
                                        <h6 className="m-0">Сайт імпортера</h6>
                                        <p className="m-0 mb-2 fs-2 text-muted">опрацьовано 18 листопада, 16:42</p>

                                        <Link to={'/'} className="d-flex align-items-center gap-1 fw-bold fs-2">
                                            <IconMessage size={20} stroke={2} /> 12
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}