import React from "react";

import logoInstagram from './../../../assets/img/instagram.svg';
import logoFacebook from './../../../assets/img/facebook.svg';
import logoStories from './../../../assets/img/stories.svg';
import { Link } from "react-router-dom";
import PublicationPlanViewPlatformProgress from "../publicationPlans/PublicationPlanViewPlatformProgress";
import {IconArrowRight, IconPlus} from "@tabler/icons-react";
import PublicationPlanCreateModal from "../publicationPlans/PublicationPlanCreateModal";


export default function PublicationPlanCard({plan = {}, planType = ''}) {

	const [newPlanModalVisible, setNewPlanModalVisible] = React.useState(false);

    const data = {
        instagram: [],
        facebook: [],
        stories: []
    };

    Object.keys(data).forEach((platform) => {
        const total = plan.discipline_rules && plan.discipline_rules[platform] && plan.discipline_rules[platform].posts_number ? plan.discipline_rules[platform].posts_number : 1;
        if (plan.publications && plan.publications[platform]) {
            plan.publications[platform].forEach((publication) => {
                let pubData = {
                    type: publication.type,
                    value: 0
                };
                switch (publication.status) {
                    case 'draft':
                        pubData.value = 25;
                        break;
                    case 'under_review':
                        pubData.value = 50;
                        break;
                    case 'scheduled':
                        pubData.value = 75;
                        break;
                    case 'published':
                        pubData.value = 100;
                        break;
                    default:
                        pubData.value = 0;
                        break;
                }
                data[platform].push(pubData);
            });
        }
        if (data[platform].length < total) {
            for (let i = data[platform].length; i < total; i++) {
                data[platform].push({ type: 'sales', value: 0 });
            }
        }
    });

	const platforms = [
		{ id: 'instagram', logo: logoInstagram, data: data['instagram'] },
		{ id: 'facebook', logo: logoFacebook, data: data['facebook'] },
		{ id: 'stories', logo: logoStories, data: data['stories'] }
	];

	function calculateAverage(data) {
		if (data.length === 0) return 0;
		const total = data.reduce((sum, item) => sum + item.value, 0);
		return total / data.length;
	}
	const overallAverage = React.useRef(calculateAverage(platforms.flatMap(platform => platform.data)));


    const formatDate = (stringDate) => {
        const months = [
            'січня',
            'лютого',
            'березня',
            'квітня',
            'травня',
            'червня',
            'липня',
            'серпня',
            'вересня',
            'жовтня',
            'листопада',
            'грудня'
        ];
        const date = new Date(stringDate);

        return date.getDate() + ' ' + months[date.getMonth()];
    }

    const linkPlan = '/'+['p',plan.id].join('/');
    const linkArchive = '/'+['p'].join('/');


    const showNewPlanModal = () => {
        setNewPlanModalVisible(true);
    }


	return (<>
		<div className="card w-100">
			<div className="card-body">
                <div className="d-flex align-items-center mb-3">
                    <div className="d-block flex-grow-1">
                        {plan.id ? (<>
                        <Link to={linkPlan} className="d-flex align-items-center h4 lh-1 fw-semibold">
                            {planType === 'current' ? 'Поточний план' : (planType === 'future' ? 'Наступний план' : 'Архівний план')}
                            <IconArrowRight size={20} stroke={2} className="ms-3"/>
                        </Link>
                        <p className="card-subtitle fs-2 mb-0">
                            від {formatDate(plan.date_since)}
                            <span className="ms-3 border-1 border-start border-dark ps-3 d-inline-flex gap-2 align-items-center">
                                <Link to={linkArchive} className="text-decoration-underline">Переглянути всі »</Link>
                            </span>
                        </p>
                        </>) : (<>
                        <div className="d-flex align-items-center h4 lh-1 fw-semibold text-muted">Плани відсутні</div>
                        </>)}
                    </div>
                    <div className="d-flex flex-grow-0 justify-content-end">
                        <button className="btn btn-outline-success" onClick={showNewPlanModal}><IconPlus size={20} stroke={2} className="me-2"/>Створити новий план</button>
                    </div>
                    <div className="flex-grow-0 text-center d-none">
                        <h5 className="card-title fs-6 fw-semibold mb-1 text-warning">{overallAverage.current.toFixed(0)}%</h5>
                        <p className="card-subtitle fs-2 mb-0 text-warning">готовність</p>
                    </div>
                </div>
                {plan.id && platforms.map(platform => (
                    <div key={platform.id} className="d-flex flex-row align-items-center gap-3 w-100 mb-3">
                        <div className="pt-1">
                            <img src={platform.logo} className="img-fluid" width={24} height={24} alt={platform.id}/>
                        </div>
                        <div className="flex-grow-1">
                            <PublicationPlanViewPlatformProgress data={platform.data}/>
						</div>
					</div>
				))}
			</div>
		</div>

        <PublicationPlanCreateModal
            visible={newPlanModalVisible}
            onClose={() => setNewPlanModalVisible(false)}
        />
	</>);
}
