import React, {useEffect} from 'react';
import {useApi} from "../../../providers/ApiProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsRequests from "../../../requests/Projects/ProjectsRequests";
import ProgressCard from "../../ui/cards/ProgressCard";

export default function PlanningTopStats({}) {
    const apiContext = useApi();
    const notificationsContext = useNotifications();
    const projectsRequests = new ProjectsRequests(apiContext.api);

    const [loading, setLoading] = React.useState(true);
    const [projects, setProjects] = React.useState([]);


    useEffect(() => {
    }, []);


    return (
        <>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <ProgressCard
                        label={'Заплановано на тиждень'}
                        value={'85%'}
                        progress={85}
                    />
                </div>
                <div className="col-lg-3 col-md-6">
                    <ProgressCard
                        label={'Опубліковано вчасно'}
                        value={'64%'}
                        progress={64}
                    />
                </div>
                <div className="col-lg-3 col-md-6">
                    <ProgressCard
                        label={'Поточна ефективність'}
                        value={'29%'}
                        progress={29}
                    />
                </div>
                <div className="col-lg-3 col-md-6">
                    <ProgressCard
                        label={'Ефективність за завданням'}
                        value={'65%'}
                        progress={65}
                        bgFill={true}
                    />
                </div>
            </div>
        </>
    );
}