import React, {useEffect} from 'react';
import {
    IconArrowNarrowLeft,
    IconArrowNarrowRight,
    IconInfoSquareRounded
} from "@tabler/icons-react";
import LineChartPlan from "../../ui/charts/LineChartPlan";
import {useApi} from "../../../providers/ApiProvider";
import {useAuth} from "../../../providers/AuthProvider";
import {useMeta} from "../../../providers/MetaProvider";
import {useParams} from "react-router-dom";
import PublicationPlansRequests from "../../../requests/Publications/PublicationPlansRequests";


export default function PublicationShortInsights({}) {
    const {planId} = useParams();
    const apiContext = useApi();
    const authContext = useAuth();
    const metaContext = useMeta();
    const projectEssentials = authContext.project;
    const publicationPlansModel = new PublicationPlansRequests(apiContext.api, projectEssentials.id);

    const [plan, setPlan] = React.useState({});
    const [loading, setLoading] = React.useState('general');
    const [mobileActiveChartIndex, setMobileActiveChartIndex] = React.useState(0);
    const totalMobileCharts = 2;


    const onClickForward = (e) => {
        e.preventDefault();
        let nextIndex = mobileActiveChartIndex + 1;
        if (nextIndex >= totalMobileCharts) {
            nextIndex = 0;
        }

        setMobileActiveChartIndex(nextIndex);
    }
    const onClickBackward = (e) => {
        e.preventDefault();
        let nextIndex = mobileActiveChartIndex - 1;
        if (nextIndex < 0) {
            nextIndex = totalMobileCharts - 1;
        }

        setMobileActiveChartIndex(nextIndex);
    }


    const visibilityDummyData = {
        current: [{x: 0, y: 0}, {x: 5, y: 0.3}, {x: 10, y: 0.7}, {x: 15, y: 1.35}, {x: 20, y: 1.9}, {x: 25, y: 2.7}, {x: 30, y: 2.9}, {x: 35, y: 4.1}, {
            x: 40,
            y: 4.9
        }, {x: 45, y: 6.4}, {x: 50, y: 7.5}, {x: 55, y: 9.1}, {x: 60, y: 9.5}],
        average: [{x: 0, y: 0}, {x: 5, y: 0.7}, {x: 10, y: 1.6}, {x: 15, y: 3.1}, {x: 20, y: 4.2}, {x: 25, y: 4.9}, {x: 30, y: 5.8}, {x: 35, y: 6}, {
            x: 40,
            y: 8.5
        }, {x: 45, y: 8.9}, {x: 50, y: 9.9}, {x: 55, y: 10.5}, {x: 60, y: 11.2}],
        best: [{x: 0, y: 0}, {x: 5, y: 1}, {x: 10, y: 2}, {x: 15, y: 4}, {x: 20, y: 5}, {x: 25, y: 7}, {x: 30, y: 9}, {x: 35, y: 10}, {x: 40, y: 11}, {
            x: 45,
            y: 11.5
        }, {x: 50, y: 12.9}, {x: 55, y: 13.5}, {x: 60, y: 17.9}],
        expected: [{x: 0, y: 0}, {x: 60, y: 8}],
    };

    const efficiencyDummyData = {
        current: [{x: 0, y: 0}, {x: 5, y: 0.3}, {x: 10, y: 0.7}, {x: 15, y: 1.35}, {x: 20, y: 1.9}, {x: 25, y: 2.7}, {x: 30, y: 2.9}, {x: 35, y: 4.1}, {
            x: 40,
            y: 4.9
        }, {x: 45, y: 6.4}, {x: 50, y: 7.5}, {x: 55, y: 9.1}, {x: 60, y: 9.5}],
        average: [{x: 0, y: 0}, {x: 5, y: 0.7}, {x: 10, y: 1.6}, {x: 15, y: 3.1}, {x: 20, y: 4.2}, {x: 25, y: 4.9}, {x: 30, y: 5.8}, {x: 35, y: 6}, {
            x: 40,
            y: 8.5
        }, {x: 45, y: 8.9}, {x: 50, y: 9.9}, {x: 55, y: 10.5}, {x: 60, y: 11.2}],
        best: [{x: 0, y: 0}, {x: 5, y: 1}, {x: 10, y: 2}, {x: 15, y: 4}, {x: 20, y: 5.2}, {x: 25, y: 7}, {x: 30, y: 9}, {x: 35, y: 10}, {x: 40, y: 11}, {
            x: 45,
            y: 11.5
        }, {x: 50, y: 12.9}, {x: 55, y: 13.5}, {x: 60, y: 17.9}],
        expected: [{x: 0, y: 0}, {x: 60, y: 8}],
    }


    return plan ? (
        <>
            <div className="d-flex gap-4 mb-st z-1">
                <button className="btn btn-sm btn-light-primary text-primary text-nowrap">Година</button>
                <button className="btn btn-sm text-primary text-nowrap">Доба</button>
                <button className="btn btn-sm text-primary">Загалом</button>
            </div>

            <div className="row block-gutter-lg">
                <div className="col-xl-6">
                    <div className={'line-chart-noted' + (mobileActiveChartIndex === 0 ? ' active' : '')}>
                        <div className="line-chart-noted-title">
                            <h3>
                                <div className="title-text">
                                    Видимість у першу годину
                                    <a href="#"><IconInfoSquareRounded size={20} stroke={2}/></a>
                                </div>
                                <div className="title-tools">
                                    <button className="btn btn-sm p-0" onClick={onClickForward}><IconArrowNarrowLeft size={20} stroke={2}/></button>
                                    <button className="btn btn-sm p-0" onClick={onClickBackward}><IconArrowNarrowRight size={20} stroke={2}/></button>
                                </div>
                            </h3>

                            <p className="chart-description">Відсоток охопленої аудиторії відносно кількості підписників.</p>
                        </div>

                        <LineChartPlan
                            data={visibilityDummyData}
                        />
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className={'line-chart-noted' + (mobileActiveChartIndex === 1 ? ' active' : '')}>
                        <div className="line-chart-noted-title">
                            <h3>
                                <div className="title-text">
                                    Ефективність у першу годину
                                    <a href="#"><IconInfoSquareRounded size={20} stroke={2}/></a>
                                </div>
                                <div className="title-tools">
                                    <button className="btn btn-sm p-0" onClick={onClickForward}><IconArrowNarrowLeft size={20} stroke={2}/></button>
                                    <button className="btn btn-sm p-0" onClick={onClickBackward}><IconArrowNarrowRight size={20} stroke={2}/></button>
                                </div>
                            </h3>

                            <p className="chart-description">Відсоток взаємодії з публікаціями відносно охопленої аудиторії.</p>
                        </div>

                        <LineChartPlan
                            data={efficiencyDummyData}
                        />
                    </div>
                </div>
            </div>
        </>
    ) : '...';
}