import React, {useEffect} from 'react';

import SimpleCardContainer from "../../ui/SimpleCardContainer";
import {useApi} from "../../../providers/ApiProvider";
import {Link, useNavigate} from "react-router-dom";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ThemesModel from "../../../requests/Projects/ProjectThemesRequests";
import ThemesForm from "./ThemesForm";
import {useMeta} from "../../../providers/MetaProvider";
import {useAuth} from "../../../providers/AuthProvider";

export default function ThemesCreate({}) {
    const navigate = useNavigate();
    const metaContext = useMeta();
    const authContext = useAuth();
    const apiContext = useApi();
    const projectEssentials = authContext.project;
    const notificationsContext = useNotifications();
    const themesModel = new ThemesModel(apiContext.api, projectEssentials.id);

    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const [formData, setFormData] = React.useState({});


    useEffect(() => {
        metaContext.update({
            title: 'Додати нову тему'
        });
    }, []);


    const onSubmit = (data) => {
        setLoading(true);

        themesModel.create(data, onCreateError).then((response) => {
            if (response !== false) {
                setLoading(false);
                setErrors({});
                setFormData(response);

                notificationsContext.notify('Тему успішно створено', 'success');

                navigate('/themes/'+response.id);
            }
        }).catch((response) => {
            onCreateError(response);
        });
    }


    const onCreateError = (response) => {
        if (response && response.data) {
            setErrors(response.data);
        }

        setLoading(false);
        notificationsContext.notify('Не вдалося створити тему', 'error');
    }


    return (<>
        <div className="d-flex m-block-gap">
            <Link to={'/themes'} className="btn btn-light-info text-info">
                <i className="ti ti-arrow-left me-2"></i>
                {'Назад до списку тем'}
            </Link>
        </div>
        <div className={'row'}>
            <div className={'col-lg-6'}>
                <SimpleCardContainer
                    title={'Додати нову тему'}
                    loading={loading}
                    cardBodyClassName={'p-0 pt-3'}
                >
                    <ThemesForm
                        formData={formData}
                        className={'py-2'}
                        errors={errors}
                        isNewRecord={true}
                        onSubmit={onSubmit}
                    />
                </SimpleCardContainer>
            </div>
        </div>
    </>);
}
