import React, {useEffect} from "react";
import FormTextArea from "../../layout/ui/FormTextArea";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import {IconArrowNarrowRight, IconX} from "@tabler/icons-react";
import {useParams} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";
import {useAuth} from "../../../providers/AuthProvider";
import {useMeta} from "../../../providers/MetaProvider";
import ProjectAIPromptsModel from "../../../requests/Projects/ProjectIAPromptsRequests";
import SimpleModal from "../../ui/SimpleModal";


export default function PublicationEditCaptionGenerateForm({publication = {}, onTextGenerated}) {
    const apiContext = useApi();
    const authContext = useAuth();
    const projectEssentials = authContext.project;
    const projectAIPromptsModel = new ProjectAIPromptsModel(apiContext.api, projectEssentials.id);

    const [loading, setLoading] = React.useState(false);
    const [prompts, setPrompts] = React.useState([]);
    const [errors, setErrors] = React.useState({});
    const [generationTextModal, setGenerationTextModal] = React.useState(false);
    const [generationMode, setGenerationMode] = React.useState(false);
    const [generationTheme, setGenerationTheme] = React.useState(0);
    const [generationText, setGenerationText] = React.useState('');
    const [generationNotes, setGenerationNotes] = React.useState('');


    useEffect(() => {
        if (publication.theme) {
            getThemes();
        }
    }, [publication.theme]);


    useEffect(() => {
        setGenerationText(prompts.find((prompt) => prompt.id === generationTheme) ? prompts.find((prompt) => prompt.id === generationTheme).text : '');
    }, [generationTheme]);


    const getThemes = () => {
        setLoading(true);

        let params = {
            theme_id: publication.theme.id,
            is_active: 1
        }
        if (publication.parameters && publication.parameters.type) {
            params.type_id = publication.parameters.type;
        }

        projectAIPromptsModel.list(params).then((response) => {
            if (response !== false) {
                setPrompts(response);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const onGenerateClick = () => {
        setLoading(true);

        projectAIPromptsModel.generate(publication.id, generationText, generationNotes).then((response) => {
            if (response !== false) {
                onTextGenerated(response);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const resetForm = () => {
        setGenerationTheme('');
        setGenerationNotes('');
        setLoading(false);
        setGenerationMode(false);
    }


    return (<div className="">
        <h4 className="fs-3 text-uppercase text-muted fw-semibold d-flex align-items-center"><i className="ti ti-arrow-left fs-4 me-2 d-none d-lg-inline"></i>Згенерувати текст з ШІ</h4>
        <p className="fs-2">При генерації будуть також враховані тип матеріалу, тема, платформа та схожі публікації.</p>

        {generationMode ? (<>
        <div className="form-group mb-3">
            <label htmlFor="pub-theme" className="form-label">Базовий запит</label>
            <select className="form-select" value={generationTheme} onChange={(e) => setGenerationTheme(parseInt(e.target.value))} id="pub-theme">
                <option value="">Оберіть базовий запит</option>
                {prompts.map((prompt) => (
                    <option key={prompt.id} value={prompt.id}>{prompt.title}</option>
                ))}
            </select>
        </div>

        {generationText ? (
            <div className="mb-2 text-end">
                <button className="btn btn-link mt-n4 p-0 text-decoration-dashed" onClick={() => setGenerationTextModal(true)}>Переглянути запит</button>
            </div>
        ) : ''}

        <SimpleModal
            headerTitle={generationText ? prompts.find((prompt) => prompt.id === generationTheme).title : ''} onClose={() => setGenerationTextModal(false)}
            modalContentClassName={'modal-xl'}
            visible={generationTextModal}
        >
            {generationText ? (
                <div className="mb-3">{generationText.split("\n").filter(line => line.trim() !== '').map((part, ind, arr) => (
                    <p key={ind} className={(ind === (arr.length - 1)) ? 'mb-0' : ''}>{part}</p>
                ))}</div>
            ) : ''}
        </SimpleModal>


        <FormTextArea
            label={'Додаткові інструкції'}
            name="pub-notes"
            value={generationNotes}
            onChange={(value) => setGenerationNotes(value)}
            rows={5}
            isCodeMirror={false}
            groupClassName={'mb-3'}
        />

        <div className="d-flex gap-3">
            <ButtonWithLoading
                label={'Згенерувати'}
                className={'btn-dark'}
                onClick={onGenerateClick}
                noOriginalIconClassName={true}
                iconClassName={"ti ti-brand-openai fs-3 me-2"}
                loading={loading}
            />
            <button className="btn btn-light d-flex align-items-center" onClick={resetForm}>
                Закрити
            </button>
        </div>
        </>) : (<>
            <button className="btn btn-outline-dark d-flex align-items-center" onClick={() => {
                setGenerationMode(true)
            }}>
                Надати інструкції
                <IconArrowNarrowRight size={20} stroke={2} className="ms-2" />
            </button>
        </>)}

    </div>);
}