import React from "react";

import './PublicationEditStatus.css';
import {IconCheck, IconInfoSquareRounded} from "@tabler/icons-react";
import {Tooltip} from "react-tooltip";


export default function PublicationEditStatus({status = 'draft'}) {

    const statuses = ['draft', 'under_review', 'scheduled', 'published'];
    const statusesTitles = ['Чернетка', 'На узгодженні', 'Запланована', 'Опублікована'];
    const hints = [
        'Не відображається у публічному плані',
        'Доступна до коментування та узгодження',
        'Очікує на публікацію',
        'Публікація успішно розміщена'
    ];

    const currentStatusIndex = statuses.indexOf(status);
    let nextStatusIndex = currentStatusIndex + 1;
    if (nextStatusIndex >= statuses.length) {
        nextStatusIndex = false;
    }

    return (<div className="publication-edit-status">
        <div className={'status-progress-vanish ' + (nextStatusIndex ? 'status-'+status.replace('_', '-') : 'd-none')}></div>

        {statuses.map((status, index) => {
            let statusClass =['status-progress-container'];
            if (index <= currentStatusIndex) {
                statusClass.push('active');
            }
            if (index === currentStatusIndex) {
                statusClass.push('current');
            }

            return (
                <div className={statusClass.join(' ')} key={index}>
                    <div className={'status-icon status-progress-'+statuses[index].replace('_', '-')}><IconCheck size={10} stroke={4}/></div>
                    <div className="title-text">
                        {statusesTitles[index]}
                        <a href="#"
                            data-tooltip-content={hints[index]}
                            data-tooltip-place="right"
                            data-tooltip-id="publication-edit-status-tooltip"
                        ><IconInfoSquareRounded size={20} stroke={2}/></a>
                    </div>
                </div>
            );
        })}

        <Tooltip id="publication-edit-status-tooltip"/>
    </div>);
}