import React, { useEffect, useRef } from 'react';

import * as L from "leaflet";
import MapHelper from "../../helpers/MapHelper";
import ZoomControl from "./ZoomControl";
import OnMapInfoPanel from "./OnMapInfoPanel";


import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { useMap } from "../../providers/MapProvider";
import {useApi} from "../../providers/ApiProvider";
import ProjectsRequests from "../../requests/Projects/ProjectsRequests";
import {useParams} from "react-router-dom";
import {useNotifications} from "../../providers/NotificationsProvider";
import ShapesModel from "../../models/ShapesModel";
import MapSearchControl from "./MapSearchControl";

import './Map.css';


export default function Map({ project = {}, canEdit = false, onProjectChange, onMapInitialized = () => {}}) {
    const {projectId} = useParams();
    const mapContext = useMap();
    const apiContext = useApi();
    const notifications = useNotifications();
    const projectsRequests = new ProjectsRequests(apiContext.api);

    const shapesModelRef = useRef(new ShapesModel(projectsRequests));

    const [curCenter, setCurCenter] = React.useState(MapHelper.getDefaultCenter());
    const [map, setMap] = React.useState(null);
    const [mapLayers, setMapLayers] = React.useState({});
    const [cursorPosition, setCursorPosition] = React.useState({ lat: 0, lng: 0 });

    let baseSelectedMapType = MapHelper.defaultMapType,
        baseLocationFilters = [1, 2];
    if (project && project.versionParameters) {
        if (project.versionParameters.map_type) {
            baseSelectedMapType = project.versionParameters.map_type;
        }
        if (project.versionParameters.location_filters) {
            baseLocationFilters = project.versionParameters.location_filters;
        }
    }
    const [selectedMapType, setSelectedMapType] = React.useState(baseSelectedMapType);

    let baseShapes = {}
    if (project && project.data && project.data.shapes) {
        baseShapes = project.data.shapes;
    }
    const [shapes, setShapes] = React.useState(baseShapes); // dry shapes

    const mapRef = React.useRef(null);
    const mapSearchedLocationRef = React.useRef(null);



    useEffect(() => {
        if (!mapContext.isInitialized) {
            let defaultMapType = 'googleMaps';

            navigator.geolocation.getCurrentPosition((position) => {
                const latLng = [position.coords.latitude, position.coords.longitude];
                const mapElements = MapHelper.initMap(mapRef.current, latLng, defaultMapType);

                setMapLayers(mapElements.layers);

                mapContext.init(mapElements.map);
            }, (error) => {
                const mapElements = MapHelper.initMap(mapRef.current, curCenter, defaultMapType);

                setMapLayers(mapElements.layers);

                mapContext.init(mapElements.map);
            },
            {
              enableHighAccuracy: true,
              timeout: 5000,
              maximumAge: 0
            });
        }
    }, []);


    useEffect(() => {
        if (mapContext.isInitialized) {
            setMap(mapContext.map);

            onMapInitialized();
            if (shapesModelRef.current.boundsValid) {
                setShapes(shapesModelRef.current.dryShapes);
            }
        }
    }, [mapContext.isInitialized]);


    useEffect(() => {
        if (map && mapLayers[selectedMapType]) {
            mapLayers[selectedMapType].addTo(map);
            map.setMaxZoom(MapHelper.defaultMaxZoom);

            Object.keys(mapLayers).forEach((key) => {
                if (key !== selectedMapType) {
                    map.removeLayer(mapLayers[key]);
                }
            });

            map.on('mousemove', onMouseMove);
        }
    }, [map, mapLayers, selectedMapType]);


    const onMouseMove = (e) => {
        setCursorPosition(e.latlng);
    };


    const onMapSearchControlChange = ({bounds, center, address}) => {
        map.fitBounds(bounds);

        if (!mapSearchedLocationRef.current) {
            mapSearchedLocationRef.current = L.marker(center).addTo(map);
        } else {
            mapSearchedLocationRef.current.setLatLng(center);
        }
    }


    return (
        <div className={'map-panel'}>
            <div id="map-container" ref={mapRef}></div>

            <div className={'map-area map-top'}>
                <MapSearchControl address={''} onChange={onMapSearchControlChange} />
            </div>

            <div className={'map-area map-corner-top-left'}>
            </div>
            <div className={'map-area map-corner-top-right'}>
            </div>
            <div className={'map-area map-corner-bottom-left'}>
                <OnMapInfoPanel data={`lat: ${cursorPosition.lat.toFixed(4)} lng: ${cursorPosition.lng.toFixed(4)}`} />
            </div>
            <div className={'map-area map-corner-bottom-right flex-row align-items-end'}>
                <ZoomControl onZoomIn={() => {
                    map.setZoom(map.getZoom() + 1)
                }} onZoomOut={() => {
                    map.setZoom(map.getZoom() - 1)
                }} />
            </div>
        </div>
    );
}