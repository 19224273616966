import React, {useEffect} from 'react';

import SimpleCardContainer from "../../ui/SimpleCardContainer";
import {useApi} from "../../../providers/ApiProvider";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import {Link, useParams} from "react-router-dom";
import {useNotifications} from "../../../providers/NotificationsProvider";
import BrandsModel from "../../../requests/Projects/ProjectTasksRequests";
import ProjectTasksForm from "./ProjectTasksForm";
import {useAuth} from "../../../providers/AuthProvider";
import {useMeta} from "../../../providers/MetaProvider";
import ProjectTasksRequests from "../../../requests/Projects/ProjectTasksRequests";
import Grades from "../../../helpers/Grades";

export default function ProjectTasksEdit(props) {
    const { taskId } = useParams();
    const apiContext = useApi();
    const authContext = useAuth();
    const projectEssentials = authContext.project;
    const notificationsContext = useNotifications();
    const metaContext = useMeta();
    const projectTasksModel = new ProjectTasksRequests(apiContext.api, projectEssentials.id);

    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState({});
    const [formData, setFormData] = React.useState({});
    const [title, setTitle] = React.useState('');


    useEffect(() => {
        loadData();
    }, []);


    const loadData = () => {
        setLoading(true);

        projectTasksModel.view(taskId).then((response) => {
            if (response !== false) {
                setFormData(response);

                let metaData = {
                    title: 'Завдання до ' + Grades.getDateSinceFormatted(response.date_for),
                }
                metaContext.update(metaData);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const onSubmit = (data) => {
        setLoading(true);

        projectTasksModel.update(taskId, data, onUpdateError).then((response) => {
            if (response !== false) {
                setErrors({});
                setFormData(response);

                notificationsContext.notify('Дані успішно оновлено', 'success');
            }
        }).catch((response) => {
            onUpdateError(response);
        }).finally(() => {
            setLoading(false);
        });
    }


    const onUpdateError = (response) => {
        if (response && response.data) {
            setErrors(response.data);
        }

        notificationsContext.notify('Не вдалося оновити дані', 'error');
    }


    return (<>
        <div className="d-flex m-block-gap">
            <Link to={'/p/tasks'} className="btn btn-light-info text-info">
                <i className="ti ti-arrow-left me-2"></i>
                {'Назад до всіх завдань'}
            </Link>
        </div>
        <div style={{maxWidth: '800px'}}>
            <SimpleCardContainer title={'Завдання до ' + Grades.getDateSinceFormatted(formData.date_for)} loading={loading}>
                <ProjectTasksForm
                    formData={formData}
                    className={'py-2'}
                    errors={errors}
                    isNewRecord={false}
                    onSubmit={onSubmit}
                />
            </SimpleCardContainer>
        </div>
    </>);
}
