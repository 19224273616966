import React, {useEffect} from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

export default function PublicationPreviewInstagramSlider({publication = {}}) {
    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };


    return (
        <Slider {...settings}>
            {publication && publication.media && publication.media.length ? publication.media.map((item, index) => {
                return (<div className={'instagram-slider-item'+(publication.platform === 'stories' ? ' ar-9-16' : '')} key={index}>
                    {item.media_type === 'video' ? <video src={item.filename} poster={item.parameters && item.parameters.preview ? item.parameters.preview : null} controls muted /> : <img src={item.filename} alt=""/>}
                </div>);
            }) : ''}
        </Slider>
    );
}