import React, {useEffect} from 'react';

import SimpleCardContainer from "../../ui/SimpleCardContainer";
import {useApi} from "../../../providers/ApiProvider";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import TableWrapper from "../../ui/TableWrapper";
import TableLoading from "../../ui/TableLoading";
import ThemesModel from "../../../requests/Projects/ProjectThemesRequests";
import BrandsModel from "../../../requests/Projects/ProjectThemesRequests";
import {useAuth} from "../../../providers/AuthProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import {useMeta} from "../../../providers/MetaProvider";
import {IconPlus} from "@tabler/icons-react";
import Pagination from "../../ui/Pagination";

export default function ThemesList({}) {
    const navigate = useNavigate();
    const metaContext = useMeta();
    const authContext = useAuth();
    const apiContext = useApi();
    const projectEssentials = authContext.project;
    const notificationsContext = useNotifications();
    const themesModel = new ThemesModel(apiContext.api, projectEssentials.id);

    const [dataItems, setDataItems] = React.useState({});
    const [pagination, setPagination] = React.useState({});
    const [filter, setFilter] = React.useState({
        searchText: null,
        sorting: {field: 'status', direction: 'asc'},
        status: '',
        page: 1
    });
    const [loading, setLoading] = React.useState(true);


    const syncData = () => {
        setLoading(true);

        themesModel.list(filter).then((response) => {
            if (response !== false) {
                let pagination = {...response};
                delete pagination.data;

                setDataItems(response.data);
                setPagination(pagination);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    useEffect(() => {
        syncData();
        updateUrl();
    }, [filter]);


    const updateUrl = () => {
        let url = '/themes';
        let query = {};
        if (filter.searchText) {
            query.filter = filter.searchText;
        }
        if (filter.status) {
            query.status = filter.status;
        }
        if (filter.sorting.field && filter.sorting.direction) {
            if (filter.sorting.field !== 'status' || filter.sorting.direction !== 'asc') {
                query.sort = filter.sorting.field;
                query.direction = filter.sorting.direction;
            }
        }
        if (Object.keys(query).length) {
            url += '?' + new URLSearchParams(query).toString();
        }
        navigate(url, {replace: true});
    }


    const onDelete = (pk) => {
        if (window.confirm('Ви впевнені, що хочете видалити цю тему?')) {
            setLoading(true);

            themesModel.delete(pk).then((response) => {
                if (response !== false) {
                    notificationsContext.notify('Тему успішно видалено', 'success');
                    syncData();
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    }


    useEffect(() => {
        metaContext.update({
            title: 'Теми публікацій'
        });

        syncData();
    }, []);


    return (<>
        <h2>Теми публікацій</h2>

        <div className="d-flex justify-content-end mb-3">
            <Link to={'/themes/create'} className={'btn btn-success'}><IconPlus size={20} stroke={2} className="me-2"/>Додати нову тему</Link>
        </div>

        {dataItems.length === 0 && !loading ? (
            <div className="text-center fs-2 mt-4">
                Теми публікацій не знайдено.
            </div>
        ) : (<>
        <TableWrapper header={[
            {'label': 'Назва', 'cellStyle': {minWidth: '40%'}},
            {'label': 'Теги'},
            {'label': '', 'cellStyle': {width: '180px'}},
        ]}>
            {loading ? (<TableLoading loading={loading} columns={4}/>) : dataItems.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>
                            <h6 className="fs-4 fw-semibold mb-1">
                                <a href={'/themes/' + item.id} className={'text-primary'}>{item.title}</a>
                            </h6>
                            <span className={'text-muted'}>Всього тегів: {item.tags.length} шт</span>
                        </td>
                        <td>{item.tags ? (<pre className={'m-0 text-wrap'}>{'#' + item.tags.join(' #')}</pre>) : (
                            <i className={'text-muted'}>Не вказано</i>)}</td>
                        <td style={{width: '180px'}}>
                            <div className="d-flex align-items-center justify-content-end gap-3">
                                <Link to={'/themes/' + item.id}
                                      className={'btn btn-light-primary btn-circle d-inline-flex align-items-center justify-content-center'}>
                                    <i className="fs-5 ti ti-edit text-primary"></i>
                                </Link>
                                <button type="button"
                                        className={'btn btn-light-danger btn-circle d-inline-flex align-items-center justify-content-center'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onDelete(item.id)
                                        }}>
                                    <i className="fs-5 ti ti-trash text-danger"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                )
            })}
        </TableWrapper>

        <div className="d-flex align-items-center justify-content-center my-4">
            <Pagination
                pagination={pagination}
                onPageChange={(page) => {
                    let newFilter = {...filter};
                    newFilter.page = page;
                    setFilter(newFilter);
                }}
            />
        </div>
        </>)}
    </>);
}