import React, {useEffect} from "react";

import {useParams} from "react-router-dom";
import PublicationEditForm from "../edit/PublicationEditForm";
import PublicationPreview from "./preview/PublicationPreview";
import PublicationEditMedia from "../edit/PublicationEditMedia";
import PublicationEditCaption from "../edit/PublicationEditCaption";
import {useApi} from "../../../providers/ApiProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsRequests from "../../../requests/Projects/ProjectsRequests";
import PublicationPlansRequests from "../../../requests/Publications/PublicationPlansRequests";
import PublicationsRequests from "../../../requests/Publications/PublicationsRequests";
import PublicationVersionsRequests from "../../../requests/Publications/PublicationVersionsRequests";
import SectionNav from "../../layout/SectionNav";

import './PublicationEdit.css';
import {IconExclamationCircle, IconMusic, IconPhoto, IconSchool, IconTag, IconTextCaption} from "@tabler/icons-react";
import PublicationEditHeader from "../header/PublicationEditHeader";
import PublicationEditStatus from "../status/PublicationEditStatus";
import Grades from "../../../helpers/Grades";
import {useMeta} from "../../../providers/MetaProvider";
import ProjectThemesRequests from "../../../requests/Projects/ProjectThemesRequests";
import {useAuth} from "../../../providers/AuthProvider";
import FormDateField from "../../layout/ui/FormDateField";
import FormTextArea from "../../layout/ui/FormTextArea";
import FormCheckbox from "../../layout/ui/FormCheckbox";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import FormSelect from "../../layout/ui/FormSelect";


export default function PublicationCreate({onSuccess}) {
    const {planId} = useParams();
    const apiContext = useApi();
    const authContext = useAuth();
    const projectEssentials = authContext.project;
    const notificationsContext = useNotifications();
    const publicationsRequests = new PublicationsRequests(apiContext.api);
    const projectThemesModel = new ProjectThemesRequests(apiContext.api, projectEssentials.id);

    const [loading, setLoading] = React.useState('');
    const [errors, setErrors] = React.useState({});
    const [unifiedScheduledFor, setUnifiedScheduledFor] = React.useState('');
    const [useUnifiedScheduledFor, setUseUnifiedScheduledFor] = React.useState(true);
    const [instagramScheduledFor, setInstagramScheduledFor] = React.useState('');
    const [facebookScheduledFor, setFacebookScheduledFor] = React.useState('');
    const [storiesScheduledFor, setStoriesScheduledFor] = React.useState('');
    const [themeId, setThemeId] = React.useState('');
    const [themes, setThemes] = React.useState([]);
    const [type, setType] = React.useState('informational');
    const [notes, setNotes] = React.useState('');
    const [isPriority, setIsPriority] = React.useState(false);


    useEffect(() => {
        getThemes();
    }, []);


    useEffect(() => {
        if (useUnifiedScheduledFor) {
            setInstagramScheduledFor(unifiedScheduledFor);
            setFacebookScheduledFor(unifiedScheduledFor);
            setStoriesScheduledFor(unifiedScheduledFor);
        } else {
            setUnifiedScheduledFor('');
        }
    }, [useUnifiedScheduledFor]);


    const getThemes = () => {
        projectThemesModel.list().then((response) => {
            if (response !== false && response.data.length > 0) {
                let themesList = [];
                response.data.forEach((theme) => {
                    themesList.push({value: theme.id, label: theme.title});
                });

                setThemes(themesList);
            } else {
                setThemes([]);
            }
        });
    }


    const validate = () => {
        let errors = {};
        let hasErrors = false;

        if (!useUnifiedScheduledFor) {
            if (!instagramScheduledFor && !facebookScheduledFor && !storiesScheduledFor) {
                errors.instagramScheduledFor = ['Вкажіть дату публікації в Instagram'];
                errors.facebookScheduledFor = ['Вкажіть дату публікації в Facebook'];
                errors.storiesScheduledFor = ['Вкажіть дату публікації в Історіях Instagram'];
                hasErrors = true;
            }
        } else {
            if (!unifiedScheduledFor) {
                errors.unifiedScheduledFor = ['Вкажіть дату публікації'];
                hasErrors = true;
            }
        }

        if (!themeId) {
            errors.themeId = ['Вкажіть тему публікації'];
            hasErrors = true;
        }

        if (!type) {
            errors.type = ['Вкажіть тип публікації'];
            hasErrors = true;
        }

        setErrors(errors);

        return !hasErrors;
    }


    const onSave = () => {
        setLoading('general');

        if (!validate()) {
            setLoading('');
            return;
        }

        const data = {
            plan_id: planId,
            type: type,
            theme_id: themeId,
            scheduled_for: useUnifiedScheduledFor ? unifiedScheduledFor : {
                instagram: instagramScheduledFor,
                facebook: facebookScheduledFor,
                stories: storiesScheduledFor
            },
            notes: notes,
            priority: isPriority
        }

        publicationsRequests.create(data).then((response) => {
            if (response !== false) {
                notificationsContext.notify('Публікації успішно створено.', 'success');

                if (typeof onSuccess === 'function') {
                    onSuccess();
                }
            } else {
                notificationsContext.notify('Помилка при створенні публікацій.', 'error');
            }
        }).finally(() => {
            setLoading('');
        });
    }


    return (<>
            <div className="publication-create">
                <div className="form-check mb-2 form-switch">
                    <input className="form-check-input" type="checkbox" id="publication-create-unified" checked={!!useUnifiedScheduledFor}
                           onChange={() => setUseUnifiedScheduledFor(!useUnifiedScheduledFor)}/>
                    <label className="form-check-label" htmlFor="publication-create-unified">Публікувати в один день на всіх платформах</label>
                </div>

                {useUnifiedScheduledFor ? (
                    <FormDateField
                        label={'Дата публікації'}
                        name={'unifiedScheduledFor'}
                        errors={errors}
                        onChange={(value) => setUnifiedScheduledFor(value)}
                        value={unifiedScheduledFor}
                        groupClassName={'mb-3'}
                    />
                ) : (<>
                    <div className="row">
                        <div className="col-lg-4">
                            <FormDateField
                                label={'Instagram'}
                                name={'instagramScheduledFor'}
                                errors={errors}
                                onChange={(value) => setInstagramScheduledFor(value)}
                                value={instagramScheduledFor}
                                groupClassName={'mb-3'}
                                groupMaxWidth={false}
                            />
                        </div>
                        <div className="col-lg-4">
                            <FormDateField
                                label={'Facebook'}
                                name={'facebookScheduledFor'}
                                errors={errors}
                                onChange={(value) => setFacebookScheduledFor(value)}
                                value={facebookScheduledFor}
                                groupClassName={'mb-3'}
                                groupMaxWidth={false}
                            />
                        </div>
                        <div className="col-lg-4">
                            <FormDateField
                                label={'Історії Instagram'}
                                name={'storiesScheduledFor'}
                                errors={errors}
                                onChange={(value) => setStoriesScheduledFor(value)}
                                value={storiesScheduledFor}
                                groupClassName={'mb-3'}
                                groupMaxWidth={false}
                            />
                        </div>
                    </div>
                </>)}

                <div className="row">
                    <div className="col-lg-8">
                        <FormSelect
                            label={'Тип публікації'}
                            name={'type'}
                            errors={errors}
                            onChange={(value) => setType(value)}
                            value={type}
                            groupClassName={'mb-3'}
                            options={[
                                {value: '', label: 'Оберіть тип публікації...'},
                                {value: 'sales', label: 'Продажний'},
                                {value: 'educational', label: 'Пізнавальний'},
                                {value: 'entertainment', label: 'Розважальний'},
                            ]}
                        />
                    </div>
                </div>

                {type === 'sales' && (
                <div className="alert alert-primary fs-2">
                    <div className="fw-medium mb-2">
                        <IconTag size={15} stroke={2} className="me-2" />
                        Продажний тип публікації позначає контент, спрямований на прямий продаж товарів та послуг.
                    </div>
                    <ul className="mb-0">
                        <li><i className="ti ti-check me-1 text-info"></i> 2 публікації на тиждень</li>
                        <li><i className="ti ti-check me-1 text-info"></i> Акції та спеціальні пропозиції з продажу, сервісу</li>
                        <li><i className="ti ti-check me-1 text-info"></i> Автомобілі в наявності з ціною</li>
                        <li><i className="ti ti-check me-1 text-info"></i> Особливі комплектації з ціною</li>
                        <li><i className="ti ti-check me-1 text-info"></i> Мерч, аксесуари та додаткові послуги (кераміка, мийка тощо) з вартістю</li>
                    </ul>
                </div>
                )}

                {type === 'educational' && (
                <div className="alert alert-primary fs-2">
                    <div className="fw-medium mb-2">
                        <IconSchool size={15} stroke={2} className="me-2" />
                        Пізнавальний тип публікації позначає контент, що розказує про особливості та можливості автомобілів.
                    </div>
                    <ul className="mb-0">
                        <li><i className="ti ti-check me-1 text-info"></i> Історія бренду</li>
                        <li><i className="ti ti-check me-1 text-info"></i> Огляд нових технологій</li>
                        <li><i className="ti ti-check me-1 text-info"></i> Поради щодо догляду за автомобілем</li>
                    </ul>
                </div>
                )}

                {type === 'entertainment' && (
                <div className="alert alert-primary fs-2">
                    <div className="fw-medium mb-2">
                        <IconMusic size={15} stroke={2} className="me-2" />
                        Розважальний тип публікації позначає контент, що не дає аудиторії засумувати, залучаючи пасивного читача до обговорення.
                    </div>
                    <ul className="mb-0">
                        <li><i className="ti ti-check me-1 text-info"></i> 2-3 публікації на тиждень</li>
                        <li><i className="ti ti-check me-1 text-info"></i> Пости-провокації: спростування догм; публікація думки, що протиречить
                            загальноприйнятій
                        </li>
                        <li><i className="ti ti-check me-1 text-info"></i> Опитування, квести, марафони</li>
                        <li><i className="ti ti-check me-1 text-info"></i> Атмосферні публікації для розуміння бренду: історія бренду, перегони, експедиції, видатні власники</li>
                    </ul>
                </div>
                )}

                <div className="row">
                    <div className="col-lg-8">
                        <FormSelect
                            label={'Тема'}
                            name={'themeId'}
                            errors={errors}
                            placeholder={'Оберіть тему публікації...'}
                            onChange={(value) => setThemeId(value)}
                            value={themeId}
                            groupClassName={'mb-3'}
                            options={themes}
                        />
                    </div>
                </div>

                <FormTextArea
                    label={'Нотатки до публікації'}
                    name={'notes'}
                    errors={errors}
                    onChange={(value) => setNotes(value)}
                    value={notes}
                    groupClassName={'mb-3'}
                />

                <FormCheckbox
                    label={'Позначити як пріоритетну'}
                    name={'isPriority'}
                    errors={errors}
                    onChange={(value) => setIsPriority(value)}
                    value={isPriority}
                    groupClassName={'mb-3'}
                />

                <ButtonWithLoading
                    className="btn-success"
                    loading={loading === 'general'}
                    onClick={onSave}
                    noOriginalIconClassName={true}
                    iconClassName="ti ti-plus fs-4 me-2"
                    label="Додати нову публікацію"
                />
            </div>
        </>
    );
}