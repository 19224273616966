import React, {useEffect} from 'react';

import SimpleCardContainer from "../../ui/SimpleCardContainer";
import {useApi} from "../../../providers/ApiProvider";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import {Link, Navigate, useParams} from "react-router-dom";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsForm from "./ProjectsForm";
import {useMeta} from "../../../providers/MetaProvider";
import ProjectsRequests from "../../../requests/Projects/ProjectsRequests";
import FacebookLoginContainer from "../../auth/FacebookLoginContainer";
import FormSelect from "../../layout/ui/FormSelect";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import {useAuth} from "../../../providers/AuthProvider";

export default function ProjectsCreateFacebook({}) {
    const metaContext = useMeta();
    const apiContext = useApi();
    const authContext = useAuth();
    const notificationsContext = useNotifications();
    const projectsRequests = new ProjectsRequests(apiContext.api);

    const [loading, setLoading] = React.useState('');
    const [error, setError] = React.useState('');
    const [pages, setPages] = React.useState({});
    const [selectedPageId, setSelectedPageId] = React.useState('');
    const [selectedPageInfo, setSelectedPageInfo] = React.useState({});
    const [redirect, setRedirect] = React.useState('');


    useEffect(() => {
        metaContext.update({
            title: 'Додати новий проєкт',
        });

        setLoading('pre');
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            setError('');
            setLoading('fetchingToken');
            projectsRequests.exchangeFacebookCode(code).then((response) => {
                if (response.access_token) {
                    setLoading('fetchingPages');
                    setError('');

                    projectsRequests.fetchPages(response.access_token).then((response) => {
                        if (response) {
                            setLoading('selectPage');
                            setPages(response);

                            setError('');
                        } else {
                            setLoading('noPages');

                            if (response && response.error && response.error.message) {
                                setError(response.error.message);
                            }
                        }
                    });
                } else {
                    setLoading('noToken');

                    if (response && response.error && response.error.message) {
                        setError(response.error.message);
                    }
                }
            });
        } else {
            setLoading('noCode');

            setError('');
        }
    }, []);


    useEffect(() => {
        if (selectedPageId) {
            const page = pages[selectedPageId];
            if (page) {
                setSelectedPageInfo(page);
            }
        }
    }, [selectedPageId]);


    const onSubmit = () => {
        setLoading('submitting');

        projectsRequests.create(selectedPageInfo).then((response) => {
            if (response.id) {
                setLoading('');
                notificationsContext.notify('Проєкт успішно створено', 'success');

                authContext.setProject(response);
            }
        }).finally(() => {
            setLoading('');
        });
    }


    return (<>
        {redirect && <Navigate to={redirect}/>}

        <div className="d-flex m-block-gap">
            <Link to={'/projects'} className="btn btn-light-info text-info">
                <i className="ti ti-arrow-left me-2"></i>
                {'Назад до всіх проєктів'}
            </Link>
        </div>

        <div className="">
            <h4 className="mb-st">Додайте новий проєкт</h4>

            {loading === 'pre' && (<div className="">Отримуємо дані від Facebook...</div>)}
            {loading === 'fetchingToken' && (<div className="">Отримуємо токен доступу...</div>)}
            {loading === 'fetchingPages' && (<div className="">Отримуємо список сторінок...</div>)}
            {loading === 'noPages' && (<div className="alert alert-danger">Не знайдено сторінок для підключення. {error}</div>)}
            {loading === 'noToken' && (<div className="alert alert-danger">Не вдалося отримати токен доступу. {error}</div>)}
            {loading === 'noCode' && (<div className="alert alert-danger">Відсутній код доступу.</div>)}

            {loading === 'selectPage' && (<div className="">
                <FormSelect
                    label={'Виберіть сторінку для проєкту:'}
                    name={'page_id'}
                    placeholder={'Оберіть сторінку...'}
                    options={Object.values(pages).map((page) => {
                        return {value: page.id, label: page.name};
                    })}
                    onChange={(value) => setSelectedPageId(value)}
                    value={selectedPageId}
                    required={true}
                    groupClassName={'mb-3'}
                />

                <ButtonWithLoading
                    label={'Обрати і продовжити'}
                    className={'btn-primary'}
                    iconClassName={'d-none'}
                    loading={loading === 'submitting'}
                    onClick={onSubmit}
                    disabled={!selectedPageId}
                />
            </div>)}

            {loading === 'processing' && (<div className="">Обробка даних...</div>)}
        </div>
    </>);
}
