import React, {useEffect} from 'react';

export default function FacebookCallback({}) {

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            const credentials = {
                facebookAuthCode: code
            }
        }
    }, []);

    return (
        <>
            <div className="text-center">
                <h4 className="mb-0">Authenticating...</h4>
            </div>
        </>
    );
}
