import React, {useEffect, useState} from 'react';

import ReactPlayer from 'react-player/file';
import {useApi} from "../../../providers/ApiProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import PublicationMediaRequests from "../../../requests/Publications/PublicationMediaRequests";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import SimpleModal from "../../ui/SimpleModal";

import 'react-image-crop/dist/ReactCrop.css';

export default function PublicationMediaPickFrame({publication, media, onMediaChanged, onModalClose}) {
    const apiContext = useApi();
    const notificationsContext = useNotifications();
    const publicationMediaModel = new PublicationMediaRequests(apiContext.api, publication.id);

    const [frameTime, setFrameTime] = useState(0);
    const [loading, setLoading] = React.useState(false);
    const playerRef = React.useRef(null);


    useEffect(() => {
    }, []);


    const onPause = () => {
        let time = playerRef.current.getCurrentTime();
        setFrameTime(time);
    }
    const onSeek = (seconds) => {
        setFrameTime(seconds);
    }


    const onSave = () => {
        if (!media) {return;}

        setLoading(true);

        publicationMediaModel.makePreview(media.id, {time: frameTime}).then((response) => {
            if (response !== false) {
                notificationsContext.notify('Прев\'ю змінено успішно', 'success');
                onMediaChanged();
                prepareModalClose();
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const prepareModalClose = () => {
        setFrameTime(0);

        onModalClose();
    }


    return !!media ? (<SimpleModal
        visible={!!media}
        loading={false}
        onClose={onModalClose}
        modalContentClassName={'modal-md'}
        headerTitle={'Редагування прев\'ю'}
    >
        <div className="video-preview-selector">
            <ReactPlayer
                url={media.filename}
                onPause={onPause}
                onSeek={onSeek}
                ref={playerRef}
                controls={true}
                volume={0}
                width={'100%'}
                height={'60vh'}
                style={{maxHeight: '60vh'}}
            />
        </div>

        <div className="my-2 fs-3 fw-semibold">Оберіть кадр для прев'ю</div>
        <ol className="my-2">
            <li>Зупиніть відео на потрібному кадрі</li>
            <li>Натисніть кнопку «Зберегти»</li>
        </ol>

        <div className="form-group mt-3 gap-3 d-flex justify-content-between">
            <button className="btn btn-muted" onClick={prepareModalClose}>Закрити без змін</button>

            <ButtonWithLoading
                className="btn btn-success"
                loading={loading}
                label={'Зберегти'}
                noOriginalIconClassName={true}
                iconClassName={'d-none'}
                onClick={onSave}
            />
        </div>
    </SimpleModal>) : '';
}