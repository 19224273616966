import React, {useEffect} from "react";

import './PublicationsPlanEdit.css';
import {useApi} from "../../../../providers/ApiProvider";
import {useAuth} from "../../../../providers/AuthProvider";
import PublicationPlansRequests from "../../../../requests/Publications/PublicationPlansRequests";
import {Link, useParams} from "react-router-dom";
import PublicationsPlanForm from "./PublicationsPlanForm";
import {useNotifications} from "../../../../providers/NotificationsProvider";
import SectionNav from "../../../layout/SectionNav";
import {IconArrowLeft} from "@tabler/icons-react";
import SimpleCardContainer from "../../../ui/SimpleCardContainer";
import ButtonWithLoading from "../../../layout/ui/ButtonWithLoading";


export default function PublicationsPlanEdit({}) {
    const {planId} = useParams();
    const apiContext = useApi();
    const authContext = useAuth();
    const notificationsContext = useNotifications();
    const projectEssentials = authContext.project;
    const publicationPlansModel = new PublicationPlansRequests(apiContext.api, projectEssentials.id);

    const [loading, setLoading] = React.useState('general');
    const [plan, setPlan] = React.useState({});


    const savePlan = (data) => {
        if (!data.date_since || !data.task_description) {
            notificationsContext.notify('Заповніть всі поля', 'error');
            return;
        }

        setLoading('saving');

        data['project_id'] = projectEssentials.id;
        publicationPlansModel.update(planId, data)
            .then((response) => {
                if (response.id) {
                    notificationsContext.notify('План публікацій успішно оновлено', 'success');

                    return getData();
                }
            })
            .catch((error) => {
                console.error('PublicationPlanCreateModal.edit', error);

                notificationsContext.notify('Помилка при збереженні плану публікацій', 'error');
            })
            .finally(() => {
                setLoading('');
            });
    }


    const getData = () => {
        setLoading('general');

        return publicationPlansModel.view(planId).then((response) => {
            if (response !== false) {
                response['date_since'] = response['date_since'].split('T')[0];

                setPlan(response);
            }
        }).finally(() => {
            setLoading('');
        });
    }


    useEffect(() => {
        getData();
    }, []);


    return (<>
        <SectionNav plan={plan} />

        <div className="publication-edit">
            <div className="publication-edit-header">
                <div className="back-button-container">
                    <Link to={'/' + ['p', planId].join('/')}
                          className="btn btn-sm btn-light-primary text-primary d-flex justify-content-center align-items-center px-0 w-auto">
                        <IconArrowLeft size={20} stroke={2}/>
                    </Link>
                </div>
                <div className="flex-grow-1">
                    <h1 className="h3 mb-st">Редагування плану публікацій</h1>

                    <div className="row">
                        <div className="col-lg-6">
                            <SimpleCardContainer
                                title={false}
                                loading={loading === 'general'}
                            >
                                <PublicationsPlanForm
                                    formData={plan}
                                    loading={loading === 'saving'}
                                    btnSubmit={{
                                        className: 'btn-primary',
                                        label: 'Зберегти зміни',
                                        icon: 'd-none'
                                    }}
                                    onSubmit={savePlan}
                                />
                            </SimpleCardContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}