import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import './PublicationPreview.css';

export default function PublicationPreviewFacebookCollage({ publication }) {
    const { media } = publication || {};
    const [layoutClassPublicationPreview, setLayoutClassPublicationPreview] = useState({});
    const [layoutClassMainImages, setLayoutClassMainImages] = useState({});
    const [layoutClassOtherImages, setLayoutClassOtherImages] = useState({});
    const [withoutOtherDiv, setWithoutOtherDiv] = useState(false);
    const [endNum, setEndNum] = useState(1);

    useEffect(() => {
        if (!media || media.length === 0) return;
        const numImages = media.length;
        const IsSquare = media[0].parameters.ar === 1;
        const isHorizontal = media[0].parameters.ar > 1;

        setLayoutClassPublicationPreview({});
        setLayoutClassMainImages({});
        setLayoutClassOtherImages({});
        setWithoutOtherDiv(false);
        setEndNum(1);

        let gridTemplateColumns = '';
        let gridTemplateRows = '';
        let gridTemplateColumnsMain = '';
        let gridTemplateRowsMain = '';
        let gridTemplateColumnsOther = '';
        let gridTemplateRowsOther = '';

        if (numImages === 1) {
            if (IsSquare) {
                gridTemplateRows = '1fr';
            } else if (isHorizontal) {
                gridTemplateColumns = '1fr';
            } else {
                gridTemplateColumns = '1fr';
                gridTemplateRows = '480px';
            }
        } else if (numImages === 2) {
            if (IsSquare) {
                gridTemplateColumns = '1fr 1fr';
                gridTemplateRows = '160px';
            } else if (isHorizontal) {
                gridTemplateColumns = '1fr';
                gridTemplateRows = '160px 159px';
                setWithoutOtherDiv(true);
            } else {
                gridTemplateColumns = '1fr 1fr';
                gridTemplateRows = '160px';
            }
        } else if (numImages === 3) {
            if (IsSquare) {
                gridTemplateRows = '160px 159px';
                gridTemplateColumnsOther = '1fr 1fr';
                gridTemplateRowsOther = '159px';
            } else if (isHorizontal) {

                gridTemplateRows = '160px 159px';
                gridTemplateColumnsOther = '1fr 1fr';
                gridTemplateRowsOther = '1fr';
            } else {
                gridTemplateColumns = '1fr 1fr';
                gridTemplateRows = '320px';
                gridTemplateColumnsOther = '1fr';
                gridTemplateRowsOther = '1fr 1fr';
            }
        } else if (numImages === 4) {
            if (IsSquare) {
                gridTemplateColumns = '1fr 1fr';
                gridTemplateRows = '159px 159px';
                setWithoutOtherDiv(true);
            } else if (isHorizontal) {
                gridTemplateRows = '214px 105px';
                gridTemplateColumnsOther = '1fr 1fr 1fr';
                gridTemplateRowsOther = '105px';
            } else {
                gridTemplateColumns = '2fr 1fr';
                gridTemplateRows = '320px';
                gridTemplateColumnsOther = '1fr';
                gridTemplateRowsOther = '105px 105px 106px';
            }
        } else {
            const sum = media.slice(2, 5).reduce((accumulator, item) => {   // Розрахунок виду заповнення зображень, коли їх 5, або більше
                return accumulator + (item.parameters.ar > 1 ? 1 : -1);
            }, 0);
            if (sum > 0) {
                gridTemplateColumns = '1fr 1fr';
                gridTemplateRows = '320px';
                gridTemplateColumnsMain = '1fr';
                gridTemplateRowsMain = '160px 158px';
                gridTemplateColumnsOther = '1fr';
                gridTemplateRowsOther = '105px 105px 106px';
            } else {
                gridTemplateRows = '160px 106px';
                gridTemplateColumnsMain = '1fr 1fr';
                gridTemplateRowsMain = '160px';
                gridTemplateColumnsOther = '1fr 1fr 1fr';
                gridTemplateRowsOther = '106px';
            }
            setEndNum(2);
        }

        setLayoutClassPublicationPreview({
            display: 'grid',
            gridGap: numImages != 1 ? '2px' : '',
            width: '100%',
            height: '100%',
            gridTemplateColumns: gridTemplateColumns,
            gridTemplateRows: gridTemplateRows,
        });
        setLayoutClassMainImages({
            display: 'grid',
            gridGap: '2px',
            gridTemplateColumns: gridTemplateColumnsMain,
            gridTemplateRows: gridTemplateRowsMain,
        });
        setLayoutClassOtherImages({
            display: 'grid',
            gridGap: '2px',
            gridTemplateColumns: gridTemplateColumnsOther,
            gridTemplateRows: gridTemplateRowsOther,
        });
    }, [media]);

    if (!media || media.length === 0) {
        return <div>No media available</div>;
    }

    const renderImages = (media, classImg = '', begNum = 0, endNum) => (
        media.slice(begNum, endNum).map((item, index) => (
            <Link to={item.filename} key={item.id || index}>
                <img src={item.filename} className={classImg} alt="" />
            </Link>
        ))
    );

    function renderImagesDiv(media, classImg = '', begNum, endNum) {
        return media.slice(begNum, endNum).map((item, index) => (
            <div key={item.id || index} className={classImg + ` ${index === 2 && media.length > 5 ? 'last-image' : ''}`}>
                {index <= 2 && (
                    <Link to={item.filename}>
                        <img src={item.filename} alt="" />
                        {index === 2 && media.length > 5 && (
                            <div className="overlay">5+</div>
                        )}
                    </Link>
                )}
            </div >
        ));
    }

    return (
        <div style={layoutClassPublicationPreview}>
            {endNum !== 2 ? renderImages(media, "main-item", 0, endNum) : (
                <div style={layoutClassMainImages}>
                    {renderImages(media, "main-item", 0, endNum)}
                </div>
            )}
            {withoutOtherDiv ? renderImagesDiv(media, "image-item", endNum) : (
                <div style={layoutClassOtherImages}>
                    {renderImagesDiv(media, "image-item", endNum)}
                </div>
            )}
        </div>
    );
}

