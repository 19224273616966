import React, { useEffect } from 'react';
import {
    IconInfoSquareRounded, IconMessage, IconPlus
} from "@tabler/icons-react";
import LineChartPlan from "../../ui/charts/LineChartPlan";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import ProjectsRequests from "../../../requests/Projects/ProjectsRequests";
import { useApi } from "../../../providers/ApiProvider";
import { useMeta } from "../../../providers/MetaProvider";
import CompetitorMetricsPreview from "../../common/CompetitorMetricsPreview/CompetitorMetricsPreview";
import { useAuth } from "../../../providers/AuthProvider";
import PublicationsPlanPreview from "../../publications/publicationPlans/preview/PublicationsPlanPreview";
import SectionNavHeader from "../../layout/SectionNavHeader";
import AudienceNav from "./AudienceNav";
import GeoTargeting from "../geotargeting/GeoTargeting";


export default function Audience({ }) {
    const apiContext = useApi();
    const authContext = useAuth();
    const metaContext = useMeta();
    const projectEssentials = authContext.project;
    const projectsRequests = new ProjectsRequests(apiContext.api);

    const [project, setProject] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        document.title = projectEssentials.title + ' | Likengale';

        getProject();
    }, []);


    useEffect(() => {
        if (project.id) {
            metaContext.update({
                title: project.title
            });
        }
    }, [project]);


    const getProject = () => {
        setLoading(true);

        projectsRequests.view(projectEssentials.id).then((response) => {
            if (response !== false) {
                setProject(response);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    return (
        <>
            <div className="d-flex flex-column block-gap">
                <AudienceNav/>

                <div className="">
                    <SimpleBar autoHide={true} className="opacity-25">
                        <div className="d-flex mb-st">
                            <button className="btn btn-sm btn-light-primary text-primary">Географія</button>
                            <button className="btn btn-sm text-primary">Демографія</button>
                            <button className="btn btn-sm text-primary">Інтереси</button>
                            <button className="btn btn-sm text-primary">Поведінка</button>
                        </div>
                    </SimpleBar>

                    <GeoTargeting />
                </div>
            </div>
        </>
    );
}