import React from "react";

import 'react-day-picker/dist/style.css';
import {DayPicker} from "react-day-picker";
import { uk } from "react-day-picker/locale";
import {IconCalendar} from "@tabler/icons-react";

export default function FormDateField({label, name, errors, onChange, value, placeholder = '', groupClassName = '', className = '', required = false, disabled = false, readOnly = false, autoComplete = 'off', autoFocus = false, hint = '', groupMaxWidth = '180px'}) {

    const [datePickerVisible, setDatePickerVisible] = React.useState(false);

    const onFieldChange = (e) => {
        onChange(e.target.value);
    }


    const handleDayPickerSelect = (date) => {
        let selectedDateParts = [date.getFullYear()];
        if (date.getMonth() < 9) {
            selectedDateParts.push('0' + (date.getMonth() + 1));
        } else {
            selectedDateParts.push(date.getMonth() + 1);
        }
        if (date.getDate() < 10) {
            selectedDateParts.push('0' + date.getDate());
        } else {
            selectedDateParts.push(date.getDate());
        }

        const selectedDate = selectedDateParts.join('-');

        onChange(selectedDate);
        setDatePickerVisible(false);
    }


    const onTriggerDatePicker = (e) => {
        e.preventDefault();

        if (disabled || readOnly) {
            return;
        }

        setDatePickerVisible(!datePickerVisible);
    }


    return (
        <div className={'form-group ' + groupClassName} style={groupMaxWidth ? {maxWidth: groupMaxWidth} : {}}>
            <label className="form-label fw-semibold mb-2" htmlFor={'cf-' + name}>{label}</label>

            <div className="input-group position-relative">
                <input id={'cf-' + name} className={'form-control ' + className} value={value ? value : ''} onChange={onFieldChange} type="text"
                       placeholder={placeholder} required={required} disabled={disabled} readOnly={readOnly} autoComplete={autoComplete} autoFocus={autoFocus}/>
                <button className="btn btn-form-input input-group-text" onClick={onTriggerDatePicker} disabled={disabled}><IconCalendar size={20} stroke={2}/></button>

                <div className={'datepicker-container' + (datePickerVisible ? '' : ' d-none')}>
                    <DayPicker
                        mode="single"
                        locale={uk}
                        selected={value ? (new Date(value)) : ''}
                        onSelect={handleDayPickerSelect}
                    />
                </div>
            </div>

            <div className="d-flex justify-content-between mt-1">
                {errors && errors[name] && <div className="form-text text-danger">{errors[name].join(' ')}</div>}
                <div className={'flex-grow-1 text-end fs-2'}>{hint}</div>
            </div>
        </div>
    )
}