import BaseCrudRequests from "../BaseCrudRequests";

/**
 * Publication Plans requests
 *
 * @param {ApiHelper} api
 */
export default class PublicationPlansRequests extends BaseCrudRequests {
    baseUrl = '/projects/{%PROJECT_ID%}/plans';

    constructor(api, projectId) {
        super(api);
        this.baseUrl = this.baseUrl.replace('{%PROJECT_ID%}', projectId);
    }


    async getPlansAround(pk, errorHandler) {
        const url = this.baseUrl + '/' + pk + '/list-around';

        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async actual(errorHandler) {
        const url = this.baseUrl+'/actual';

        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


	async examine(pk, errorHandler) {
		let url = this.baseUrl + '/' + pk + '/examine';

		const response = await this.api.postRequest(url);

		return this.processResponse(response, errorHandler);
	}
}
