import * as L from "leaflet";
import * as turf from "@turf/turf";
import "leaflet.gridlayer.googlemutant";

export default class MapHelper {
    static defaultCenter = L.latLng(51.5062769,-0.1061091);
    static defaultZoomLevel = 15;
    static defaultZoomLevelDetailed = 17;
    static defaultMaxZoom = 20;
    static defaultMapType = 'googleHybrid';


    static getDefaultCenter() {
        let user = localStorage.getItem('user');
        if (user) {
            user = JSON.parse(user);
            if (user.settings && user.settings.home) {
                return L.latLng(user.settings.home[0], user.settings.home[1]);
            }
        }

        return MapHelper.defaultCenter;
    }


    static createOSMStandardLayer() {
        return L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; OpenStreetMap contributors',
            detectRetina: true,
        });
    }


    static createGoogleMapsLayer() {
        return L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            attribution: '&copy; Google Maps'
        });
    }


    static createGoogleHybridLayer() {
        return L.tileLayer('https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', {
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            attribution: '&copy; Google Maps'
        });
    }


    static initMap(htmlContainer, position = null, baseMapType = '') {
        let mapOptions = {
            fadeAnimation: true,
            zoomAnimation: true,
            zoomControl: false,
            doubleClickZoom: false,
            maxZoom: MapHelper.defaultMaxZoom,
        };

        let map = L.map(htmlContainer, mapOptions);

        //let OSMStandardLayer = MapHelper.createOSMStandardLayer();
        //let googleMapsLayer = MapHelper.createGoogleMapsLayer();
        let googleHybridLayer = MapHelper.createGoogleHybridLayer();


        if (!position) {
            position = MapHelper.getDefaultCenter();
        }
        map.setView(position, MapHelper.defaultZoomLevel);


        return {
            map: map,
            layers: {
                //OSMStandard: OSMStandardLayer,
                //googleMaps: googleMapsLayer,
                googleHybrid: googleHybridLayer,
            }
        };
    }
}