import React, {useEffect} from 'react';
import SectionNav from "../../layout/SectionNav";
import {
    IconCalendarMonth,
    IconExclamationCircle,
} from "@tabler/icons-react";
import PublicationsListTabled from "../../publications/common/PublicationsListTabled/PublicationsListTabled";
import {useApi} from "../../../providers/ApiProvider";
import {useAuth} from "../../../providers/AuthProvider";
import {useMeta} from "../../../providers/MetaProvider";
import {useParams} from "react-router-dom";
import PublicationPlansRequests from "../../../requests/Publications/PublicationPlansRequests";
import Grades from "../../../helpers/Grades";
import PublicationPlanChecklist from "./PublicationPlanChecklist";
import SimpleModal from "../../ui/SimpleModal";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import PublicationsRequests from "../../../requests/Publications/PublicationsRequests";
import dayjs from "dayjs";


export default function PublicationPlanView({}) {
    const {planId} = useParams();
    const apiContext = useApi();
    const authContext = useAuth();
    const metaContext = useMeta();
    const projectEssentials = authContext.project;
    const publicationPlansModel = new PublicationPlansRequests(apiContext.api, projectEssentials.id);
    const publicationsRequests = new PublicationsRequests(apiContext.api);

    const [plan, setPlan] = React.useState({});
    const [publications, setPublications] = React.useState({});
    const [errors, setErrors] = React.useState([]);
    const [loading, setLoading] = React.useState('general');


    const getPublications = () => {
        setLoading('general');

        publicationsRequests.list({plan_id: planId}).then(response => {
            const groupedPublications = groupPlainPublications(response);
            setPublications(groupedPublications);
        }).finally(() => {
            setLoading('');
        });
    }


    /**
     * Grouped and sorted by date ASC -> theme title ASC -> time ASC
     *
     * @param publications
     * @returns {Array}
     */
    const groupPlainPublications = (publications) => {
        let groupedPublications = {};

        publications.forEach((publication) => {
            const scheduledForLocal = dayjs(publication.scheduled_for).locale('uk').format('YYYY-MM-DD');
            const themeTitle = publication.theme ? publication.theme.title : 'Без теми';

            if (!groupedPublications[scheduledForLocal]) {
                groupedPublications[scheduledForLocal] = {};
            }

            if (!groupedPublications[scheduledForLocal][themeTitle]) {
                groupedPublications[scheduledForLocal][themeTitle] = [];
            }

            groupedPublications[scheduledForLocal][themeTitle].push(publication);
        });

        Object.keys(groupedPublications).forEach((scheduledFor, scheduledForIndex) => {
            Object.keys(groupedPublications[scheduledFor]).forEach((themeTitle, themeTitleIndex) => {
                let groupPublications = [...groupedPublications[scheduledFor][themeTitle]];
                groupPublications.sort((a, b) => {
                    return a.scheduled_for.localeCompare(b.scheduled_for);
                });

                groupedPublications[scheduledFor][themeTitle] = groupPublications;
            });

            // sort object by keys
            let ordered = {};
            Object.keys(groupedPublications[scheduledFor]).sort().forEach(function(key) {
                ordered[key] = groupedPublications[scheduledFor][key];
            });
            groupedPublications[scheduledFor] = ordered;
        });

        // sort object by keys
        let ordered = {};
        Object.keys(groupedPublications).sort().forEach(function(key) {
            ordered[key] = groupedPublications[key];
        });
        groupedPublications = ordered;

        return groupedPublications;
    }


    useEffect(() => {
        getPlanData();
    }, []);


    const getPlanData = () => {
        setLoading('general');

        publicationPlansModel.view(planId).then((response) => {
            if (response !== false) {
                setPlan(response);

                updateMetaData(response);
                getPublications();
            }
        }).finally(() => {
            setLoading('');
        });
    }


    const updateMetaData = (data) => {
        const newTitle = 'План від ' + Grades.getDateSinceFormatted(data.date_since) + ' для ' + data.project.title + ' | Likengale';

        metaContext.update({
            title: newTitle
        });
    }


    return plan ? (
        <>
            <SectionNav plan={plan} />

            <div className="d-flex flex-column block-gap">
                <div className="row block-gutter">
                    <div className="col-xl-7">
                        <div className="d-flex flex-column block-gap-single">
                            <div className="simple-block">
                                <h5>Тема та нотатки</h5>
                                {plan.task_description ? (
                                    <div>{plan.task_description}</div>
                                ) : (
                                    <div><em className="text-muted">Не вказані.</em></div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5">
                        {plan.id && (<PublicationPlanChecklist plan={plan} publications={publications} />)}
                        {/*<PublicationPlanShortInsights />*/}
                    </div>
                </div>

                <PublicationsListTabled
                    publications={publications}
                    onPublicationsChanged={getPublications}
                />
            </div>
        </>
    ) : '...';
}