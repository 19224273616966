import React, {useEffect} from 'react';
import {useApi} from "../../../providers/ApiProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsRequests from "../../../requests/Projects/ProjectsRequests";
import {Navigate, useParams} from "react-router-dom";
import PublicationPlansRequests from "../../../requests/Publications/PublicationPlansRequests";
import SimpleModal from "../../ui/SimpleModal";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";



export default function PublicationPlanButtonModal({}) {
    const {projectId} = useParams();
    const apiContext = useApi();
    const notificationsContext = useNotifications();
    const projectsRequests = new ProjectsRequests(apiContext.api);
    const publicationPlansModel = new PublicationPlansRequests(apiContext.api);

    const [modalVisible, setModalVisible] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [createdPlanId, setCreatedPlanId] = React.useState(null);

    const [startDate, setStartDate] = React.useState('');
    const [theme, setTheme] = React.useState('');


    const onModalClose = () => {
        setModalVisible(false);
    }


    const createNewPlan = () => {
        if (!startDate || !theme) {
            notificationsContext.notify('Заповніть всі поля', 'error');
            return;
        }

        setLoading(true);

        const data = {
            project_id: projectId,
            date_since: startDate,
            task_description: theme
        };
        publicationPlansModel.create(data)
            .then((response) => {
                if (response.id) {
                    notificationsContext.notify('План публікацій успішно створено', 'success');
                    onModalClose();

                    setCreatedPlanId(response.id);
                }
            })
            .catch((error) => {
                notificationsContext.notify('Помилка при створенні плану публікацій', 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    }


    return (
        <>
            {createdPlanId && (<Navigate to={'/'+['p', projectId, createdPlanId].join('/')} />)}

            <button className="btn btn-light-success text-success me-3" onClick={() => setModalVisible(!modalVisible)}>
                <i className="ti ti-plus me-2"></i>Створити план
            </button>

            <SimpleModal
                visible={modalVisible}
                loading={loading}
                onClose={onModalClose}
                modalContentClassName={'modal-lg'}
                headerTitle={'Створення нового плану публікацій'}
            >
                <div className="form-group mb-3">
                    <label className="form-label">Дата початку</label>
                    <input type="date" className="form-control w-auto" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                </div>

                <div className="form-group mb-4">
                    <label className="form-label">Тема</label>
                    <input type="text" className="form-control" value={theme} onChange={(e) => setTheme(e.target.value)} />
                </div>

                <div className="form-group">
                    <ButtonWithLoading
                        className="btn btn-success"
                        loading={loading}
                        label={'Створити новий план'}
                        noOriginalIconClassName={true}
                        iconClassName={'ti ti-plus fs-4 me-2'}
                        onClick={createNewPlan}
                    />
                </div>
            </SimpleModal>
        </>
    );
}
