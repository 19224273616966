import React, {useState, useCallback} from "react";

import SimpleBar from "simplebar-react";
import {useAuth} from "../../../providers/AuthProvider";

import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min';
import imgProfileTmp from '../../../assets/img/blank.png';
import {Link} from "react-router-dom";
import {IconSettings} from "@tabler/icons-react";


function HeaderProfile({}) {
    const authContext = useAuth();
    const userData = authContext.user;
    const projectEssentials = authContext.project;

    const [hasNotifications, setHasNotifications] = useState(true);

    let imgProfile = imgProfileTmp;
    if (projectEssentials && projectEssentials.logo) {
        imgProfile = projectEssentials.logo;
    }

    let imgUserPic = imgProfileTmp;
    if (userData.avatar) {
        imgUserPic = userData.avatar;
    }


    const getLabelByRole = (role) => {
        let label = 'Без ролі';
        switch (role) {
            case 'admin': label = 'Адміністратор'; break;
        }

        return label;
    }


    return (<>
        <div id="header-profile-label" data-bs-toggle="dropdown" aria-expanded="false">
            <div className={'header-profile-image' + (hasNotifications ? ' active' : '')}>
                <img src={imgProfile} alt="Profile image" />
            </div>

            <div className="header-profile-name d-none d-lg-flex">
                <div className="header-profile-title">
                    <h6>{projectEssentials && projectEssentials.title ? projectEssentials.title : (userData.full_name ? userData.full_name : 'Без імені')}</h6>

                    <IconSettings size={17} stroke={3}/>
                </div>

                <span>{projectEssentials && projectEssentials.id ? '@'+projectEssentials.id : userData.username}</span>
            </div>
        </div>

        <div className="dropdown-menu dropdown-menu-end mt-3" style={{width: '300px'}} aria-labelledby="header-profile-label">
            <SimpleBar autoHide={true} className={'profile-dropdown position-relative'}>
                <div className="d-flex align-items-center py-9 mx-7 border-bottom">
                    <img src={imgUserPic} className="rounded-circle" width="80" height="80" alt=""/>
                    <div className="ms-3">
                        <h5 className="mb-2">{userData.full_name ? userData.full_name : 'Без імені'}</h5>
                        <span className="mb-0 d-block text-dark">{getLabelByRole(userData.role)}</span>
                        <p className="mb-0 d-flex text-dark align-items-center">
                            {userData.email}
                        </p>
                    </div>
                </div>
                <div className="message-body">
                    <Link to={'/themes'} reloadDocument className="py-8 px-7 mt-8 d-flex align-items-center">
                        <span className="d-flex align-items-center justify-content-center bg-light rounded-1 p-6">
                            <i className={'ti ti-hash fs-6'}></i>
                        </span>
                        <div className="w-75 d-inline-block v-middle ps-3">
                            <h6 className="mb-0 bg-hover-primary fw-semibold"> Теми публікацій</h6>
                            <span className="d-block text-dark fs-2">та хештеги до них</span>
                        </div>
                    </Link>
                    <Link to={'/checklists'} reloadDocument className="py-8 px-7 d-flex align-items-center">
                        <span className="d-flex align-items-center justify-content-center bg-light rounded-1 p-6">
                            <i className={'ti ti-checklist fs-6'}></i>
                        </span>
                        <div className="w-75 d-inline-block v-middle ps-3">
                        <h6 className="mb-0 bg-hover-primary fw-semibold"> Чек-лісти</h6>
                            <span className="d-block text-dark fs-2">до контент-планів</span>
                        </div>
                    </Link>
                    <Link to={'/projects'} reloadDocument className="py-8 px-7 d-flex align-items-center">
                        <span className="d-flex align-items-center justify-content-center bg-light rounded-1 p-6">
                            <i className={'ti ti-refresh fs-6'}></i>
                        </span>
                        <div className="w-75 d-inline-block v-middle ps-3">
                            <h6 className="mb-0 bg-hover-warning fw-semibold"> Змінити проєкт</h6>
                        </div>
                    </Link>
                    <hr className="border-top mx-7 my-8 opacity-100" />
                    <Link to={'/settings/connected-accounts'} reloadDocument className="py-8 px-7 d-flex align-items-center">
                        <span className="d-flex align-items-center justify-content-center bg-light rounded-1 p-6">
                            <i className={'ti ti-user fs-6'}></i>
                        </span>
                        <div className="w-75 d-inline-block v-middle ps-3">
                            <h6 className="mb-0 bg-hover-primary fw-semibold"> Мої дані</h6>
                        </div>
                    </Link>
                    <Link to={'/settings'} reloadDocument className="py-8 px-7 d-flex align-items-center">
                        <span className="d-flex align-items-center justify-content-center bg-light rounded-1 p-6">
                            <i className={'ti ti-settings fs-6'}></i>
                        </span>
                        <div className="w-75 d-inline-block v-middle ps-3">
                            <h6 className="mb-0 bg-hover-primary fw-semibold"> Налаштування</h6>
                        </div>
                    </Link>
                </div>
                <div className="d-grid py-4 px-7 pt-8">
                    <a href="/auth/logout" className="btn justify-content-center btn-outline-primary">Завершити сеанс</a>
                </div>
            </SimpleBar>
        </div>
    </>)
}

export default HeaderProfile;