import React from 'react';
import ReactDOM from 'react-dom/client';
import * as L from "leaflet";

import 'react-toastify/dist/ReactToastify.css';
import 'simplebar-react/dist/simplebar.min.css';
import 'modernize-essentials/dist/css/style.min.css';
import 'react-day-picker/dist/style.css';

import "./assets/css/base/theme.css";
import "./assets/css/styles/merged.css";


import reportWebVitals from './reportWebVitals';


import {library} from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import * as Brands from '@fortawesome/free-brands-svg-icons';


import {RouterProvider} from "react-router-dom";
import {AuthProvider} from "./providers/AuthProvider";
import {ApiProvider} from "./providers/ApiProvider";
import {NotificationsProvider} from "./providers/NotificationsProvider";
import {BreadcrumbsProvider} from "./providers/BreadcrumbsProvider";
import {router} from "./router";
import {GoogleMapsApiProvider} from "./providers/googleMapsApiProvider";
import {MetaProvider} from "./providers/MetaProvider";

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {MapProvider} from "./providers/MapProvider";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [13, 41]
});
L.Marker.prototype.options.icon = DefaultIcon;



const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);
library.add(...iconList);

const brandList = Object.keys(Brands)
    .filter((key) => key !== 'fab' && key !== 'prefix')
    .map((icon) => Brands[icon]);
library.add(...brandList);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthProvider>
        <ApiProvider>
            <GoogleMapsApiProvider>
                <NotificationsProvider>
                    <BreadcrumbsProvider>
                        <MetaProvider>
                            <MapProvider>
                                <RouterProvider router={router} />
                            </MapProvider>
                        </MetaProvider>
                    </BreadcrumbsProvider>
                </NotificationsProvider>
            </GoogleMapsApiProvider>
        </ApiProvider>
    </AuthProvider>
);

reportWebVitals();