/**
 * Base CRUD requests
 *
 * @param {ApiHelper} api
 */
export default class BaseCrudRequests {
    baseUrl = '/some-sample-base-url';

    constructor(api) {
        this.api = api;
    }


    async list(filters = {}, errorHandler) {
        let url = this.baseUrl;
        let query = {};
        if (filters.search) {
            query.search = filters.search;
            delete filters.search;
        }
        if (filters.sort_by && filters.sort_dir) {
            query.sort_by = filters.sort_by;
            query.sort_dir = filters.sort_dir;
            delete filters.sort_by;
            delete filters.sort_dir;
        }
        if (filters.page) {
            query.page = filters.page;
            delete filters.page;
        }
        Object.keys(filters).forEach(key => {
            query[key] = filters[key];
        });
        if (Object.keys(query).length) {
            url += '?' + new URLSearchParams(query).toString();
        }

        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async view(pk, errorHandler) {
        const url = this.baseUrl+'/' + pk;

        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async create(data, errorHandler) {
        const url = this.baseUrl;

        const response = await this.api.postRequest(url, data);

        return this.processResponse(response, errorHandler);
    }


    async update(pk, data, errorHandler) {
        const url = this.baseUrl+'/' + pk;

        const response = await this.api.putRequest(url, data);

        return this.processResponse(response, errorHandler);
    }


    async delete(pk, errorHandler) {
        const url = this.baseUrl+'/' + pk;

        const response = await this.api.deleteRequest(url);

        return this.processResponse(response, errorHandler);
    }


    processResponse(response, errorHandler) {
        if (response.status.toString().startsWith('20') && response.data && response.data.success === true && response.data.data) {
            return response.data.data;
        } else {
            if (errorHandler) {
                errorHandler(response.data);
            }
        }

        return false;
    }
}
