import React, {useEffect} from 'react';

import SimpleCardContainer from "../../ui/SimpleCardContainer";
import {useApi} from "../../../providers/ApiProvider";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ThemesModel from "../../../requests/Projects/ProjectThemesRequests";
import ThemesForm from "./ThemesForm";
import {useMeta} from "../../../providers/MetaProvider";
import {useAuth} from "../../../providers/AuthProvider";

export default function ThemesEdit({}) {
    const {themeId} = useParams();
    const navigate = useNavigate();
    const metaContext = useMeta();
    const authContext = useAuth();
    const apiContext = useApi();
    const projectEssentials = authContext.project;
    const notificationsContext = useNotifications();
    const themesModel = new ThemesModel(apiContext.api, projectEssentials.id);

    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState({});
    const [formData, setFormData] = React.useState({});


    useEffect(() => {
        metaContext.update({
            title: 'Редагувати тему'
        });

        loadData();
    }, []);


    const loadData = () => {
        themesModel.view(themeId).then((response) => {
            if (response !== false) {
                if (typeof response.tags === 'object') {
                    response.tags = response.tags.join(', ');
                }
                setFormData(response);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const onSubmit = (data) => {
        setLoading(true);

        themesModel.update(themeId, data, onUpdateError).then((response) => {
            if (response !== false) {
                setErrors({});

                notificationsContext.notify('Тема успішно оновлена', 'success');
                loadData();
            }
        }).catch((response) => {
            onUpdateError(response);
        }).finally(() => {
            setLoading(false);
        });
    }


    const onUpdateError = (response) => {
        if (response && response.data) {
            setErrors(response.data);
        }

        notificationsContext.notify('Не вдалося оновити тему', 'error');
    }


    return (<>
        <div className="d-flex m-block-gap">
            <Link to={'/themes'} className="btn btn-light-info text-info">
                <i className="ti ti-arrow-left me-2"></i>
                {'Назад до списку тем'}
            </Link>
        </div>
        <div className={'row'}>
            <div className={'col-lg-6'}>
                <SimpleCardContainer
                    title={'Редагувати тему'}
                    loading={loading}
                    cardBodyClassName={'p-0 pt-3'}
                >
                    <ThemesForm
                        formData={formData}
                        className={'py-2'}
                        errors={errors}
                        isNewRecord={false}
                        onSubmit={onSubmit}
                    />
                </SimpleCardContainer>
            </div>
        </div>
    </>);
}
