import React, {useEffect} from "react";

import {useParams} from "react-router-dom";
import PublicationEditForm from "../edit/PublicationEditForm";
import PublicationPreview from "./preview/PublicationPreview";
import PublicationEditMedia from "../edit/PublicationEditMedia";
import PublicationEditCaption from "../edit/PublicationEditCaption";
import {useApi} from "../../../providers/ApiProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsRequests from "../../../requests/Projects/ProjectsRequests";
import PublicationPlansRequests from "../../../requests/Publications/PublicationPlansRequests";
import PublicationsRequests from "../../../requests/Publications/PublicationsRequests";
import PublicationVersionsRequests from "../../../requests/Publications/PublicationVersionsRequests";
import SectionNav from "../../layout/SectionNav";

import './PublicationEdit.css';
import {IconExclamationCircle, IconPhoto, IconTextCaption} from "@tabler/icons-react";
import PublicationEditHeader from "../header/PublicationEditHeader";
import PublicationEditStatus from "../status/PublicationEditStatus";
import Grades from "../../../helpers/Grades";
import {useMeta} from "../../../providers/MetaProvider";


export default function PublicationEdit({}) {
    const {planId, publicationId} = useParams();
    const apiContext = useApi();
    const metaContext = useMeta();
    const notificationsContext = useNotifications();
    const projectsRequests = new ProjectsRequests(apiContext.api);
    const publicationPlansModel = new PublicationPlansRequests(apiContext.api);
    const publicationsRequests = new PublicationsRequests(apiContext.api);
    const publicationVersionsModel = new PublicationVersionsRequests(apiContext.api, publicationId);

    const [loading, setLoading] = React.useState('general');
    const [captionEditMode, setCaptionEditMode] = React.useState(false);
    const [publication, setPublication] = React.useState({});


    useEffect(() => {
        getData();
    }, []);


    const getData = () => {
        setLoading('general');

        publicationsRequests.view(publicationId).then((response) => {
            if (response !== false) {
                setPublication(response);

                const pageTitle = 'Публікація в ' + response.platforms[response.platform] + ' від ' + Grades.getDateSinceFormatted(response.scheduled_for);
                metaContext.update({title: pageTitle});
            }
        }).finally(() => {
            setLoading('');
        });
    }


    const onMediaChanged = () => {
        getData();
    }


    const onCaptionChanged = (newCaption) => {
        setPublication({...publication, latestVersion: newCaption});
    }


    return publication ? (<>
        <SectionNav plan={publication.plan} displayEdit={false} />

        <div className="publication-edit">
            <PublicationEditStatus status={publication.status}/>

            <PublicationEditHeader publication={publication} onPublicationChanged={getData} />

            {['draft', 'under_review'].includes(publication.status) ? (
            <div className="publication-edit-contents">
                <PublicationPreview publication={publication}/>

                <div className="publication-edit-contents-working-area d-flex align-items-start">
                    {publication.platform === 'stories' ? (
                    <div className="w-100">
                        <PublicationEditMedia
                            publication={publication}
                            onMediaChanged={onMediaChanged}
                        />
                    </div>
                    ) : (<>
                    <div className="nav nav-pills" role="tablist" aria-orientation="vertical">
                        <button className="nav-link active" id="publication-edit-media-tab" data-bs-toggle="pill" data-bs-target="#publication-edit-media"
                                type="button" role="tab"
                                aria-controls="publication-edit-media" aria-selected="true"><IconPhoto size={24} stroke={2}/>
                        </button>
                        <button className={'nav-link'+(publication.platform === 'stories' ? ' opacity-25' : '')} id="publication-edit-caption-tab" data-bs-toggle="pill" data-bs-target="#publication-edit-caption"
                                type="button" role="tab"
                                aria-controls="publication-edit-caption" aria-selected="false"><IconTextCaption size={24} stroke={2}/>
                        </button>
                    </div>
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="publication-edit-media" role="tabpanel" aria-labelledby="publication-edit-media-tab"
                             tabIndex="0">
                            <PublicationEditMedia
                                publication={publication}
                                onMediaChanged={onMediaChanged}
                            />
                        </div>
                        <div className="tab-pane fade" id="publication-edit-caption" role="tabpanel" aria-labelledby="publication-edit-caption-tab"
                             tabIndex="0">
                            <PublicationEditCaption
                                publication={publication}
                                onCaptionChanged={onCaptionChanged}
                            />
                        </div>
                    </div>
                    </>)}
                </div>
            </div>
            ) : (
                <></>
            )}
        </div>
    </>) : '';
}