import React, {useEffect} from 'react';
import ProgressBarStepped from "../../ui/ProgressBarStepped/ProgressBarStepped";
import {useApi} from "../../../providers/ApiProvider";
import {useAuth} from "../../../providers/AuthProvider";
import PublicationPlansRequests from "../../../requests/Publications/PublicationPlansRequests";
import {IconAlertSquareRounded, IconArrowNarrowRight, IconBellExclamation} from "@tabler/icons-react";
import SimpleModal from "../../ui/SimpleModal";
import PublicationPlanProcess from "./PublicationPlanProcess";


export default function PublicationPlanChecklist({plan = {}, publications = {}}) {
    const apiContext = useApi();
    const authContext = useAuth();
    const projectEssentials = authContext.project;
    const publicationPlansModel = new PublicationPlansRequests(apiContext.api, projectEssentials.id);

    const [statusChangeModalVisible, setStatusChangeModalVisible] = React.useState(false);
    const [disciplineErrors, setDisciplineErrors] = React.useState([]);
    const [processingAvailability, setProcessingAvailability] = React.useState(false);


    useEffect(() => {
        examineDiscipline();
    }, [publications]);


    const examineDiscipline = () => {
        publicationPlansModel.examine(plan.id).then((response) => {
            if (response.hasOwnProperty('errors')) {
                setDisciplineErrors(response.errors);
            } else {
                setDisciplineErrors([]);
            }
        });
    }


    const processingAvailabilityChanged = (availability) => {
        setProcessingAvailability(availability);
    }


    return plan ? (
        <div className="simple-block">
            <div className="opacity-25">
                <h5>Чек-ліст</h5>
                <ProgressBarStepped
                    total={10}
                    progress={4}
                    label="42%"
                />
            </div>

            <div className="text-end opacity-25">
                <button className="btn btn-sm btn-link btn-link-warning d-inline-flex text-decoration-dashed h-auto p-0 mt-2" id="open-checklist-label"
                        data-bs-toggle="dropdown" aria-expanded="false">Відкрити чек-ліст
                </button>

                <div className="dropdown-menu" aria-labelledby="open-checklist-label">
                    <div className="d-flex align-items-center justify-content-between py-3 px-7">
                        <h5 className="mb-0 fs-5 fw-semibold">Чек-ліст</h5>
                        <span className="badge bg-primary rounded-4 px-3 py-1 lh-sm">5 new</span>
                    </div>
                </div>
            </div>

            {disciplineErrors.length ? (
                <div className="alert alert-danger mt-st fs-2 text-danger">
                    <ul className="m-0">
                        {disciplineErrors.map((error, index) => (
                            <li key={index}>{error.message}</li>
                        ))}
                    </ul>
                </div>
            ) : ''}

            <div className="d-flex align-items-center justify-content-end mt-st">
                <button className="btn btn-primary" onClick={() => setStatusChangeModalVisible(true)}>
                    Перевести на узгодження
                    {processingAvailability ? (
                        <IconArrowNarrowRight size={20} stroke={2} className="ms-2" />
                    ) : (
                        <IconBellExclamation size={20} stroke={2} className="ms-2" />
                    )}
                </button>
            </div>

            <SimpleModal
                headerTitle="Перевести план на узгодження"
                visible={statusChangeModalVisible}
                onClose={() => setStatusChangeModalVisible(false)}
            >
                <PublicationPlanProcess
                    plan={plan}
                    publications={publications}
                    onClose={() => setStatusChangeModalVisible(false)}
                    onProcessingAvailabilityChange={processingAvailabilityChanged}
                />
            </SimpleModal>
        </div>
    ) : '...';
}