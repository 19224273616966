import BaseCrudRequests from "../BaseCrudRequests";

/**
 * Project Tasks requests
 *
 * @param {ApiHelper} api
 */
export default class ProjectTasksRequests extends BaseCrudRequests {
    baseUrl = '/projects/{%PROJECT_ID%}/tasks';

    constructor(api, projectId) {
        super(api);
        this.baseUrl = this.baseUrl.replace('{%PROJECT_ID%}', projectId);
    }
}
