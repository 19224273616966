import React, { useEffect } from 'react';
import SimpleBar from "simplebar-react";
import ProjectsRequests from "../../../requests/Projects/ProjectsRequests";
import { useApi } from "../../../providers/ApiProvider";
import { useMeta } from "../../../providers/MetaProvider";
import { useAuth } from "../../../providers/AuthProvider";
import Map from "../../map/Map";


export default function GeoTargeting({ }) {
    const apiContext = useApi();
    const authContext = useAuth();
    const metaContext = useMeta();
    const projectEssentials = authContext.project;
    const projectsRequests = new ProjectsRequests(apiContext.api);

    const [project, setProject] = React.useState({});
    const [loading, setLoading] = React.useState(true);


    return (
        <div className="row block-gutter">
            <div className="col-xl-8">
                <Map />
            </div>
            <div className="col-xl-4">
            </div>
        </div>
    );
}