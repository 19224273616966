import React, {useCallback, useEffect} from 'react';
import {useDropzone} from 'react-dropzone'
import PublicationAddFile from "./PublicationAddFile";

import PublicationMediaPreviewsContainer from "./PublicationMediaPreviewsContainer";
import {useApi} from "../../../providers/ApiProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import PublicationMediaRequests from "../../../requests/Publications/PublicationMediaRequests";
import PublicationMediaCrop from "./PublicationMediaCrop";
import PublicationMediaPickFrame from "./PublicationMediaPickFrame";


export default function PublicationEditMedia({publication, onMediaChanged}) {
    const onDrop = useCallback(acceptedFiles => {
        setFilesToUpload(acceptedFiles);
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    const apiContext = useApi();
    const notificationsContext = useNotifications();
    const publicationMediaRequests = new PublicationMediaRequests(apiContext.api, publication.id);

    const [uploadRunning, setUploadRunning] = React.useState(false);
    const [filesToUpload, setFilesToUpload] = React.useState([]);
    const [fileUploadStatuses, setFileUploadStatuses] = React.useState({});
    const [uploadComplete, setUploadComplete] = React.useState(false);
    const [mediaToCrop, setMediaToCrop] = React.useState(null);
    const [mediaToPickFrame, setMediaToPickFrame] = React.useState(null);


    const onUploadClick = () => {
        if (filesToUpload.length === 0) {return;}

        let statuses = {};
        for (let i = 0; i < filesToUpload.length; i++) {
            statuses[i] = 'pending';
        }
        setFileUploadStatuses(statuses);

        setUploadRunning(true);
    }


    const onPerformUpload = (index, status) => {
        setFileUploadStatuses(prevStatus => ({
            ...prevStatus,
            [index]: status
        }));
    }


    useEffect(() => {
        onUploadClick();
    }, [filesToUpload]);


    useEffect(() => {
        let statuses = Object.values(fileUploadStatuses);
        if (statuses.length === 0) {return;}

        if (statuses.indexOf('pending') === -1 && statuses.indexOf(false) === -1) {
            setUploadComplete(true);

            onMediaChanged();
        }

        if (statuses.indexOf('pending') === -1) {
            setUploadRunning(false);
        }
    }, [fileUploadStatuses]);


    useEffect(() => {
        if (uploadComplete) {
            notificationsContext.notify('Медіа завантажено', 'success');

            setFilesToUpload([]);
            setFileUploadStatuses({});
            setUploadComplete(false);
        }
    }, [uploadComplete]);


    const onOrderChanged = (items) => {
        let sortedItems = {};
        items.forEach((item, index) => {
            sortedItems[item.id] = index;
        });

        publicationMediaRequests.updateOrder(sortedItems).then((response) => {
            if (response !== false) {
                onMediaChanged();

                notificationsContext.notify('Порядок медіа змінено', 'success');
            }
        });
    }


    const onSelectPreview = (mediaItem) => {
        setMediaToPickFrame(mediaItem);
    }


    const onCrop = (mediaItem) => {
        setMediaToCrop(mediaItem);
    }


    const onDelete = (mediaItem) => {
        return publicationMediaRequests.delete(mediaItem.id).then((response) => {
            if (response !== false) {
                onMediaChanged();
            }
        });
    }


    return (<>
        <h4 className="fs-5 fw-semibold mt-0 mb-3">Медіа до публікації</h4>
        <div className="mb-5">
            <div {...getRootProps()} className={'drop-area'+((isDragActive)?' active':'')}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p className="m-0">{'Перетягніть файли сюди...'}</p> :
                        <p className="m-0">{'Ви можете перенести один чи декілька файлів сюди, або натисніть і оберіть їх вручну.'}</p>
                }
            </div>

            <div className="mt-3">
                {filesToUpload && filesToUpload.map((file, index) => (
                    <PublicationAddFile
                        key={index}
                        file={file}
                        publicationId={publication.id}
                        performUpload={uploadRunning}
                        onPerformUpload={(status) => {
                            onPerformUpload(index, status);
                        }}
                    />
                ))}
            </div>

            <PublicationMediaPreviewsContainer
                disabled={false}
                areStories={publication.platform === 'stories'}
                items={publication.media ? publication.media : []}

                onOrderChanged={onOrderChanged}
                onSelectPreview={onSelectPreview}
                onCrop={onCrop}
                onDelete={onDelete}
            />
        </div>

        <PublicationMediaCrop
            publication={publication}
            media={mediaToCrop}
            onMediaChanged={onMediaChanged}
            onModalClose={() => {setMediaToCrop(null)}}
        />

        <PublicationMediaPickFrame
            publication={publication}
            media={mediaToPickFrame}
            onMediaChanged={onMediaChanged}
            onModalClose={() => {setMediaToPickFrame(null)}}
        />
    </>
    );
}