import React, {useEffect} from 'react';
import {useApi} from "../../../providers/ApiProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectCard from "../../projects/common/ProjectCard";
import ProjectsRequests from "../../../requests/Projects/ProjectsRequests";
import PlanningTopStats from "./PlanningTopStats";
import PlanningNextWeek from "./PlanningNextWeek";
import ProjectCardStats from "../../projects/common/ProjectCardStats";
import {useMeta} from "../../../providers/MetaProvider";
import ProjectEfficiencyCard from "../../projects/common/ProjectEfficiencyCard";
import {useParams} from "react-router-dom";
import ProjectTitle from "../../projects/common/ProjectTitle";
import ProjectTitleNumbers from "../../projects/common/ProjectTitleNumbers";
import ProjectActivity from "../../projects/common/ProjectActivity";
import PublicationPlanCard from "../../publications/common/PublicationPlanCard";
import ProjectCompetitorsStats from "../../projects/common/ProjectCompetitorsStats";

export default function PlanningProject({}) {
    const apiContext = useApi();
    const {projectId} = useParams();
    const notificationsContext = useNotifications();
    const metaContext = useMeta();
    const projectsRequests = new ProjectsRequests(apiContext.api);

    const [loading, setLoading] = React.useState(true);
    const [project, setProject] = React.useState({});


    useEffect(() => {
        getProject();
    }, []);


    useEffect(() => {
        if (project.id) {
            metaContext.update({
                title: project.title
            });
        }
    }, [project]);


    const getProject = () => {
        setLoading(true);

        projectsRequests.view(projectId).then((response) => {
            if (response !== false) {
                setProject(response);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    return (
        <>
            <div className="row">
                <div className="col-lg-4">
                    <ProjectTitle project={project} loading={loading}/>
                </div>
                <div className="col-lg-8">
                    <ProjectTitleNumbers project={project} loading={loading}/>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-8">
                    <ProjectEfficiencyCard
                        project={project}
                        loading={loading}
                        cardClassName="m-block-gap"
                    />

                    <ProjectCompetitorsStats />
                </div>
                <div className="col-lg-4">
                    <PublicationPlanCard
                        projectId={projectId}
                    />

                    <ProjectActivity loading={loading} project={project} />
                </div>
            </div>
        </>
    );
}