import React, {useEffect} from "react";
import Grades from "../../../helpers/Grades";
import {IconCalendarMonth, IconExclamationCircle, IconRubberStamp, IconSquareRoundedCheckFilled, IconWriting, IconX} from "@tabler/icons-react";
import SimpleModal from "../../ui/SimpleModal";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import {useApi} from "../../../providers/ApiProvider";
import {useAuth} from "../../../providers/AuthProvider";
import PublicationPlansRequests from "../../../requests/Publications/PublicationPlansRequests";
import PublicationsRequests from "../../../requests/Publications/PublicationsRequests";

export default function PublicationCardProgress({publication, className = 'mt-3', onUpgradeStatus}) {
    const apiContext = useApi();
    const publicationsRequests = new PublicationsRequests(apiContext.api);

    const [progressClassName, setProgressClassName] = React.useState('light');

    const [errors, setErrors] = React.useState([]);
    const [progress, setProgress] = React.useState(0);

    const blankChecklist = {
        media: false,
        caption: false,
        scheduled_for: false
    };
    const [checklist, setChecklist] = React.useState(blankChecklist);

    const setClassNameByGrade = () => {
        const curClassName = Grades.getClassNameByGrade(progress);

        setProgressClassName(curClassName);
    }


    useEffect(() => {
        setClassNameByGrade();
    }, [progress]);


    useEffect(() => {
        if (!publication || !publication.id) {setProgress(0); return;}

        let newProgress = 5,
            newChecklist = {...blankChecklist};

        if (publication.media.length) {
            newProgress += 45;
            newChecklist.media = true;
        }

        if (publication.platform !== 'stories' && publication.latestVersion && publication.latestVersion.text) {
            newProgress += 25;
            newChecklist.caption = true;
        } else if (publication.platform === 'stories') {
            newProgress *= 1.5;
            newChecklist.caption = true;
        }

        if (publication.scheduled_for) {
            newProgress += 5;
            newChecklist.scheduled_for = true;
        }

        if (errors.length) {
            newProgress = Math.round(newProgress / 2);
        } else {
            newProgress += 20;
        }

        setChecklist(newChecklist);
        setProgress(newProgress);
    }, [publication, errors]);


    useEffect(() => {
        validatePublication();
    }, [publication]);


    const validatePublication = () => {
        if (!publication || !publication.id) {return;}


        publicationsRequests.examine(publication.id).then((response) => {
            if (response.hasOwnProperty('errors')) {
                setErrors(response.errors);
            } else {
                setErrors([]);
            }
        });
    }


    const getLabelByProgress = () => {
        if (errors.length) {
            return 'Необхідно усунути недоліки, '+errors.length+' шт.';
        }

        if (!checklist.media && !checklist.caption && !checklist.scheduled_for) {
            return 'Наповнення очікується.';
        }

        let missingParts = [];
        if (!checklist.media) {
            missingParts.push('медіа');
        }
        if (!checklist.caption) {missingParts.push('підпис');}
        if (!checklist.scheduled_for) {missingParts.push('дату публікації');}

        if (missingParts.length) {
            return 'Необхідно додати '+missingParts.join(' та ') + '.';
        }

        return 'Готово до публікації.';
    }


    return (
        <div className={'publication-card-progress '+className}>
            <div className="fs-5 fw-semibold text-start pb-0 d-flex justify-content-between">
                {errors.length ? (
                    <h5 className="text-danger">
                        <IconExclamationCircle size={20} stroke={2} className="me-2"/>
                        Потрібна увага
                    </h5>
                ) : (progress === 100 ? (
                    <h5 className="text-success">
                        <IconSquareRoundedCheckFilled size={20} stroke={2} className="me-2"/>
                        Підготовку завершено!
                    </h5>
                ) : (
                    <h5>Готовність</h5>
                ))}
                <span>{Math.round(progress)}%</span>
            </div>
            <h6 className="card-subtitle mb-2 fs-2 text-muted text-muted text-start">{getLabelByProgress()}</h6>

            <div className="progress bg-light" style={{height: '4px'}}>
                <div className={'progress-bar bg-'+progressClassName} role="progressbar" style={{width: progress+'%', height: '4px'}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
            </div>

            {errors.length ? (
                <div className="alert alert-danger mt-3 fs-2 text-danger">
                    <ul className="m-0">
                        {errors.map((error, index) => (
                            <li key={index}>{error.message}</li>
                        ))}
                    </ul>
                </div>
            ) : (((progress === 100) && publication && ['draft', 'under_review'].includes(publication.status)) ? (<>
                <div className="d-flex align-items-center justify-content-end my-st">
                    {publication.status === 'draft' ? (<>
                        <button className="btn btn-primary" onClick={onUpgradeStatus}>
                            <IconRubberStamp size={20} stroke={2} className="me-2" />
                            Поставити на узгодження
                        </button>
                    </>) : (<>
                        <button className="btn btn-success" onClick={onUpgradeStatus}>
                            <IconCalendarMonth size={20} stroke={2} className="me-2"/>
                            Запланувати публікацію
                        </button>
                    </>)}
                </div>
            </>
            ) : '')}

            {publication.status === 'scheduled' ? (
                <div className="d-flex align-items-center justify-content-end my-st">
                    <button className="btn btn-danger" onClick={onUpgradeStatus}>
                        <IconX size={20} stroke={2} className="me-2"/>
                        Відмінити публікацію
                    </button>
                </div>
            ) : ''}
        </div>
    );
}