import React, {useEffect} from 'react';

import {useApi} from "../../../providers/ApiProvider";
import {Link, Navigate, useParams} from "react-router-dom";
import {useNotifications} from "../../../providers/NotificationsProvider";
import {useMeta} from "../../../providers/MetaProvider";
import ProjectsRequests from "../../../requests/Projects/ProjectsRequests";
import FacebookLogin from "@greatsumini/react-facebook-login";

export default function ProjectsCreate({}) {
    const metaContext = useMeta();
    const apiContext = useApi();
    const notificationsContext = useNotifications();
    const projectsRequests = new ProjectsRequests(apiContext.api);

    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState({});
    const [formData, setFormData] = React.useState({});
    const [title, setTitle] = React.useState('');
    const [redirect, setRedirect] = React.useState('');


    useEffect(() => {
        metaContext.update({
            title: 'Додати новий проєкт',
        });

        setLoading(false);
    }, []);


    const onSubmit = (data) => {
        setLoading(true);

        projectsRequests.create(data, onCreateError).then((response) => {
            if (response !== false) {
                setLoading(false);
                setErrors({});
                setFormData(response);

                notificationsContext.notify('Проєкт успішно створено', 'success');

                setRedirect('/projects/'+response.id);
            }
        }).catch((response) => {
            onCreateError(response);
        });
    }


    const onCreateError = (response) => {
        if (response && response.data) {
            setErrors(response.data);
        }

        setLoading(false);
        notificationsContext.notify('Не вдалося створити проєкт', 'error');
    }


    return (<>
        {redirect && <Navigate to={redirect}/>}
        <div className="d-flex m-block-gap">
            <Link to={'/projects'} className="btn btn-light-info text-info">
                <i className="ti ti-arrow-left me-2"></i>
                Назад до всіх проєктів
            </Link>
        </div>

        <div className="">
            <h4 className="mb-st">Додайте новий проєкт</h4>

            <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                useRedirect={true}
                initParams={{
                    version: process.env.REACT_APP_FACEBOOK_GRAPH_VERSION,
                    xfbml: true,
                }}
                dialogParams={{
                    config_id: process.env.REACT_APP_CONFIGURATION_SYSTEM_USERS,
                    redirect_uri: window.location.origin + process.env.REACT_APP_FACEBOOK_REDIRECT_URI_SYSTEM_USERS
                }}
                loginOptions={{
                    return_scopes: true,
                }}

                render={(renderProps) => (
                    <button onClick={renderProps.onClick} className="btn btn-facebook">
                        Підключити через Facebook
                    </button>
                )}
            />
        </div>
    </>);
}
