import React, { useState, useEffect } from 'react';
import * as L from "leaflet";
import AddressAutocomplete from "./AddressAutocomplete";

export default function MapSearchControl({address, onChange}) {
    const [currentAddress, setCurrentAddress] = useState(address);


    useEffect(() => {
        setCurrentAddress(address);
    }, [address]);


    const processAutocompleteResponse = (place) => {
        if (!place || !place.geometry || !place.geometry.location) {return;}

        let locationData = {
            center: { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() },
            bounds: false,
            address: null
        };

        if (place.geometry.viewport) {
            const ne = place.geometry.viewport.getNorthEast();
            const sw = place.geometry.viewport.getSouthWest();

            locationData.bounds = L.latLngBounds({lat: sw.lat(), lng: sw.lng()}, {lat: ne.lat(), lng: ne.lng()});
        }

        if (place.formatted_address) {
            setCurrentAddress(place.formatted_address);
            locationData.address = place.formatted_address;
        }

        onChange(locationData);
    };


    return (
        <div className={'flex-grow-1'}>
            <div className="position-absolute end-0 top-50 translate-middle-y pe-4 me-1"><i className="ti ti-search fs-4"></i></div>

            <AddressAutocomplete
                value={currentAddress ? currentAddress : ''}
                onChange={processAutocompleteResponse}
                className={'form-control input-onmap'}
            />
        </div>
    );
}