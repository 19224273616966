import React, {useEffect} from 'react';
import {useApi} from "../../../providers/ApiProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectCard from "../../projects/common/ProjectCard";
import ProjectsRequests from "../../../requests/Projects/ProjectsRequests";
import PlanningTopStats from "./PlanningTopStats";
import PlanningNextWeek from "./PlanningNextWeek";
import ProjectCardStats from "../../projects/common/ProjectCardStats";
import {useMeta} from "../../../providers/MetaProvider";

export default function Planning({}) {
    const apiContext = useApi();
    const notificationsContext = useNotifications();
    const metaContext = useMeta();
    const projectsRequests = new ProjectsRequests(apiContext.api);

    const [loading, setLoading] = React.useState(true);
    const [projects, setProjects] = React.useState([]);


    useEffect(() => {
        metaContext.update({
            title: 'Планування',
            description: 'Планування контенту',
            keywords: 'планування, контент, планування контенту',
        });

        getProjects();
    }, []);


    const getProjects = () => {
        setLoading(true);

        projectsRequests.statsShortWeekly().then((response) => {
            if (response !== false) {
                setProjects(response.data);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    return (
        <>
            <div className="row">
                <div className="col-xl-7 col-xxl-8">
                    <PlanningTopStats/>

                    <div className={'row'}>
                        {projects && projects.length > 0 && projects.map((project, index) => (
                            <div className={'col-lg-6 col-xxl-4'} key={index}>
                                <ProjectCard
                                    loading={loading}
                                    link={'/p/' + project.id}
                                    title={project.title}
                                    cover={project.cover}
                                    logo={project.logo}
                                >
                                    {project.stats && (<ProjectCardStats
                                        planned={project.stats.scheduled ? project.stats.scheduled * 100 : 0}
                                        published={project.stats.published ? project.stats.published * 100 : 0}
                                        efficiency={project.stats.efficiency ? project.stats.efficiency * 100 : 0}
                                        correspondence={project.stats.correspondence ? project.stats.correspondence * 100 : 0}
                                    />)}
                                </ProjectCard>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-xl-5 col-xxl-4">
                    <PlanningNextWeek />
                </div>
            </div>
        </>
    );
}