import BaseCrudRequests from "../BaseCrudRequests";

/**
 * Project AI Prompts requests
 *
 * @param {ApiHelper} api
 */
export default class ProjectAIPromptsModel extends BaseCrudRequests {
    baseUrl = '/projects/{%PROJECT_ID%}/ai-prompts';

    constructor(api, projectId) {
        super(api);
        this.baseUrl = this.baseUrl.replace('{%PROJECT_ID%}', projectId);
    }


    async generate(publicationId, mainPrompt, additionalPrompt, errorHandler) {
        const url = this.baseUrl + '/generate';

        const data = {
            publication_id: publicationId,
            main_prompt: mainPrompt,
            additional_prompt: additionalPrompt,
        };

        const response = await this.api.postRequest(url, data);

        return this.processResponse(response, errorHandler);
    }
}
