import React, {useEffect} from 'react';
import {useApi} from "../../../providers/ApiProvider";
import {useAuth} from "../../../providers/AuthProvider";
import PublicationPlansRequests from "../../../requests/Publications/PublicationPlansRequests";
import imgPlatformStories from "../../../assets/img/upgraded/instagram-stories.svg";
import imgPlatformFacebook from "../../../assets/img/upgraded/fb.svg";
import imgPlatformInstagram from "../../../assets/img/upgraded/ig.svg";
import imgBlank from "../../../assets/img/blank.png";
import Grades from "../../../helpers/Grades";
import {Link} from "react-router-dom";
import {IconCheck, IconX} from "@tabler/icons-react";
import PublicationsRequests from "../../../requests/Publications/PublicationsRequests";
import StrHelper from "../../../helpers/StrHelper";
import {Tooltip} from "react-tooltip";
import ProgressBarStepped from "../../ui/ProgressBarStepped/ProgressBarStepped";


export default function PublicationPlanProcess({plan = {}, publications = {}, onClose, onProcessingAvailabilityChange}) {
    const apiContext = useApi();
    const authContext = useAuth();
    const projectEssentials = authContext.project;
    const publicationPlansModel = new PublicationPlansRequests(apiContext.api, projectEssentials.id);
    const publicationsRequests = new PublicationsRequests(apiContext.api);

    const [statusChangeModalVisible, setStatusChangeModalVisible] = React.useState(false);
    const [processingStatuses, setProcessingStatuses] = React.useState({});
    const [processingAvailable, setProcessingAvailable] = React.useState(false);


    const [loading, setLoading] = React.useState('general');


    useEffect(() => {
        let newProcessingStatuses = {};
        Object.keys(publications).map(pubDate => {
            Object.keys(publications[pubDate]).map(pubTheme => {
                publications[pubDate][pubTheme].map(publication => {
                    newProcessingStatuses[publication.id] = {
                        status: 'processing'
                    };
                });
            });
        });

        setProcessingStatuses(newProcessingStatuses);
        processPublicationStatuses(newProcessingStatuses);
    }, [publications]);


    useEffect(() => {
        let allDone = true;

        if (Object.keys(processingStatuses).length) {
            Object.keys(processingStatuses).map(pubId => {
                if (processingStatuses[pubId].status !== 'done') {
                    allDone = false;
                }
            });
        }

        setProcessingAvailable(allDone);
    }, [processingStatuses]);


    useEffect(() => {
        if (typeof onProcessingAvailabilityChange === 'function') {
            onProcessingAvailabilityChange(processingAvailable);
        }
    }, [processingAvailable]);

    const processPublicationStatuses = (newProcessingStatuses) => {
        Object.keys(newProcessingStatuses).map((pubId) => {
            examinePublication(pubId).then((results) => {
                setProcessingStatuses((prevStatuses) => {
                    return {...prevStatuses, [results.id]: results.status ? {status: 'done'} : {status: 'error', description: results.description}};
                });
            });
        });
    }


    const examinePublication = (pubId) => {
        return publicationsRequests.examine(pubId).then((response) => {
            if (response.hasOwnProperty('errors')) {
                return {id: pubId, status: false, description: response.errors};
            } else {
                return {id: pubId, status: true};
            }
        });
    }


    const processPlan = () => {
        console.log('Processing plan');
    }


    const getPlatformIcon = (platform) => {
        switch (platform) {
            case 'instagram':
                return imgPlatformInstagram;
            case 'facebook':
                return imgPlatformFacebook;
            case 'stories':
                return imgPlatformStories;
            default:
                return imgBlank;
        }
    }


    const getPublicationUrl = (publicationId, planId) => {
        return '/' + ['p', planId, publicationId].join('/');
    }


    const getStatusIndex = (status, statuses) => {
        return Object.keys(statuses).indexOf(status) + 1;
    }


    return plan ? (<>
        <div className="d-flex flex-column gap-4">
        {Object.keys(publications).map((pubDate, dateInd) => (
            <div className="d-flex flex-column gap-2 fs-2" key={dateInd}>
                <h5 className="">{Grades.getDateSinceFormatted(pubDate, null, false, true)}</h5>
                <div className="d-flex flex-column gap-3">
                    {Object.keys(publications[pubDate]).map((pubTheme, themeInd) => (
                        <div className="" key={themeInd}>
                            <h6 className="">{pubTheme}</h6>
                            <div className="">
                                {publications[pubDate][pubTheme].map((publication, pubInd) => (
                                    <Link to={getPublicationUrl(publication.id, publication.plan.id)} className="d-flex align-items-center justify-content-between text-dark bg-on-hover py-1 px-2" key={pubInd}>
                                        <div className="d-flex align-items-center">
                                            <img src={getPlatformIcon(publication.platform)} alt={publication.platform + ' icon'} className="img-icon me-2"/>
                                            <span className="d-block" style={{width: '100px'}}>{publication.platforms[publication.platform]}</span>

                                            <div className="ms-3 ms-lg-5" style={{width: '50px'}}>
                                                <ProgressBarStepped
                                                    total={4}
                                                    progress={getStatusIndex(publication.status, publication.statuses)}
                                                    label={false}
                                                />
                                            </div>
                                        </div>

                                        {(!processingStatuses[publication.id] || processingStatuses[publication.id].status === 'processing') ? (
                                            <div className="d-flex align-items-center">
                                                <div className="spinner-border spinner-border-sm text-primary me-2" role="status">
                                                <span className="visually-hidden">Опрацьовується...</span>
                                                </div>
                                                Перевірка...
                                            </div>
                                        ) : (
                                        processingStatuses[publication.id].status === 'done' ? (
                                            <div className="d-flex align-items-center">
                                                <IconCheck size={16} stroke={2} className="me-2"/>
                                                Все добре
                                            </div>
                                        ) : (
                                            <div
                                                className="d-flex align-items-center text-danger"

                                                data-tooltip-html={processingStatuses[publication.id].description.length ? processingStatuses[publication.id].description.map(error => error.message).join('<br />') : ''}
                                                data-tooltip-id="publications-examine-tooltip"
                                            >
                                                <IconX size={16} stroke={2} className="me-2"/>
                                                {processingStatuses[publication.id].description.length} {StrHelper.wordForm(processingStatuses[publication.id].description.length, ['помилка', 'помилки', 'помилок'])}
                                            </div>
                                        ))}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        ))}
        </div>

        <Tooltip id="publications-examine-tooltip"/>

        <div className="mt-4">
            {processingAvailable ? (
            <button className="btn btn-primary" onClick={processPlan}>
                Перевести на узгодження
            </button>
            ) : (
                <div className="text-danger fs-2 text-center">Переведення неможливе. Необхідно усунути всі помилки.</div>
            )}
        </div>
    </>) : '';
}