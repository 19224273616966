import BaseCrudRequests from "../BaseCrudRequests";

/**
 * Projects requests
 *
 * @param {ApiHelper} api
 */
export default class ProjectsRequests extends BaseCrudRequests {
    baseUrl = '/projects';

    constructor(api) {
        super(api);
    }


    async exchangeFacebookCode(code, errorHandler) {
        let url = this.baseUrl + '/exchange-facebook-code';
        const response = await this.api.postRequest(url, {code: code});

        return this.processResponse(response, errorHandler);
    }


    async refreshPageAccessToken(code, projectId, errorHandler) {
        let url = this.baseUrl + '/refresh-facebook-access-token';
        const response = await this.api.postRequest(url, {code: code, project_id: projectId});

        return this.processResponse(response, errorHandler);
    }


    async fetchPages(token, errorHandler) {
        let url = this.baseUrl + '/fetch-pages';
        const response = await this.api.postRequest(url, {token: token});

        return this.processResponse(response, errorHandler);
    }


    async statsShortWeekly(dateSince = null, errorHandler) {
        let url = this.baseUrl + '/stats-short-weekly';
        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }
}