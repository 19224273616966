import BaseCrudRequests from "../BaseCrudRequests";

/**
 * Publication Versions requests
 *
 * @param {ApiHelper} api
 */
export default class PublicationVersionsRequests extends BaseCrudRequests {
    baseUrl = '/publications/{%PUBLICATION_ID%}/versions';

    constructor(api, publicationId) {
        super(api);
        this.baseUrl = this.baseUrl.replace('{%PUBLICATION_ID%}', publicationId);
    }
}
